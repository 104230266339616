import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslate, linkToRecord } from 'react-admin'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles(theme => ({
	button: {
		color: theme.palette.primary.main,
	},
}))

const EditButton = ({ basePath, record }) => {
	const t = useTranslate()
	const classes = useStyles()

	return (
		<Link to={linkToRecord(basePath, record.id)}>
			<Button size="small" className={classes.button} startIcon={<EditIcon />}>
				{t('ra.action.edit')}
			</Button>
		</Link>
	)
}

export default EditButton
