import React from 'react'
import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@material-ui/core'

const ConfirmationMailDialog = ({ title, open, message, handleMessageChange, handleClose, handleSubmit }) => {
	return <Dialog open={open} onClose={handleClose}>
		<DialogTitle id="form-dialog-title">{title}</DialogTitle>
		<DialogContent>
			<DialogContentText>
				You can write further information for your guest.
				This message will be sent in the mail.
			</DialogContentText>
			<TextField
				margin="dense"
				label="Message"
				value={message}
				onChange={handleMessageChange}
				autoFocus
				fullWidth
				multiline
			/>
		</DialogContent>
		<DialogActions>
			<Button onClick={handleClose} color="primary">
				Cancel
			</Button>
			<Button onClick={handleSubmit} color="primary">
				Send mail
			</Button>
		</DialogActions>
	</Dialog>
}

export default ConfirmationMailDialog
