import * as React from 'react'
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Settings as SettingsIcon } from '@material-ui/icons'

const useStyles = makeStyles({
	title: {
		flex: 1,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textTransform: 'uppercase',
		fontSize: 16,
		fontWeight: 500,
	},
	spacer: {
		flex: 1,
	},
})

const CustomUserMenu = props => {
	const t = useTranslate()

	return (
		<UserMenu {...props}>
			<MenuItemLink
				to={'/Profile'}
				primaryText={t('profile.name')}
				leftIcon={<SettingsIcon />}
				sidebarIsOpen
			/>
		</UserMenu>
	)
}

const CustomAppBar = props => {
	const classes = useStyles()

	return (
		<AppBar {...props} userMenu={<CustomUserMenu />}>
			<Typography variant="h1" className={classes.title} id="react-admin-title" />
			<span className={classes.spacer} />
		</AppBar>
	)
}

export default CustomAppBar
