import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin'
import { useMediaQuery } from '@material-ui/core'

import QueryFilter from 'components/queryFilter'

const UserList = props => {
	const isMedium = useMediaQuery(theme => theme.breakpoints.up('sm'))

	return (
		<List {...props} filters={<QueryFilter />}>
			<Datagrid>
				<TextField source="firstName" />
				<TextField source="lastName" />
				{isMedium && <TextField source="email" />}
				<EditButton />
				<DeleteButton />
			</Datagrid>
		</List>
	)
}

export default UserList
