import { useEffect, useReducer } from 'react'
import { gql } from '@apollo/client'
import isEqual from 'lodash/isEqual'

import client from 'api/client'

const GET_PAGE_CONTENTS = gql`
	query getPage($pageSlug: String) {
		Page(slug: $pageSlug) {
			slug
			contents {
				id
				type
				min
				max
				title
				imageName
				order
				lockedOrder
				items {
					... on ContentBlock {
						id
						name
						shortDescription
						description
						imageName
						order
					}
					... on ContentSlide {
						id
						name
						description
						imageName
						order
					}
					... on ContentCard {
						id
						name
						description
						imageName
						order
					}
					... on ContentDescription {
						id
						name
						description
						imageName
						order
					}
				}
			}
		}
	}
`

const UPDATE_CONTENT = gql`
	mutation updateContent($id: UUID!, $title: String, $imageBase64: String, $order: Int, $items: [JSON]) {
		updateContent(id: $id, title: $title, imageBase64: $imageBase64, order: $order, items: $items) {
			id
		}
	}
`

const reducer = (state, action) => {
	switch (action.type) {
		case 'init':
			return {
				...state,
				contents: action.payload,
			}
		case 'setChanges':
			return {
				...state,
				changes: action.payload,
				hasContentChanges: !!Object.keys(action.payload).length,
			}
		case 'setErrors':
			return {
				...state,
				errors: action.payload,
			}
		case 'submit':
			return {
				...state,
				changes: {},
				hasContentChanges: false,
			}
	}
}

const usePageContent = pageSlug => {
	const [state, dispatch] = useReducer(reducer, {
		contents: null,
		errors: {},
		hasContentChanges: false,
	})

	const getContents = () => {
		client
			.query({
				query: GET_PAGE_CONTENTS,
				variables: { pageSlug },
				fetchPolicy: 'no-cache',
			})
			.then(res => {
				if (res.data.Page) {
					dispatch({ type: 'init', payload: res.data.Page.contents })
				} else {
					dispatch({ type: 'init', payload: [] })
				}
			})
	}

	const updateContent = updatedContent => {
		const changes = { ...state.changes }

		const initialContent = state.contents.find(content => content.id === updatedContent.id)
		if (!isEqual(updatedContent, initialContent)) {
			changes[updatedContent.id] = updatedContent
		} else {
			delete changes[updatedContent.id]
		}

		dispatch({ type: 'setChanges', payload: changes })
	}

	const updateContentErrors = (id, updatedErrors) => {
		const errors = { ...state.errors }

		if (Object.keys(updatedErrors).length > 0) {
			errors[id] = updatedErrors
		} else {
			delete errors[id]
		}

		dispatch({ type: 'setErrors', payload: errors })
	}

	const submitContents = () => {
		Object.entries(state.changes).forEach(([id, content]) => {
			client
				.mutate({
					mutation: UPDATE_CONTENT,
					variables: { id, ...content },
				})
				.then(() => {
					dispatch({ type: 'submit' })
				})
		})
	}

	useEffect(() => {
		getContents()
		// eslint-disable-next-line
	}, [])

	return {
		contents: state.contents,
		hasContentChanges: state.hasContentChanges,
		hasContentErrors: state.hasContentErrors,
		updateContent,
		updateContentErrors,
		submitContents,
	}
}

export default usePageContent
