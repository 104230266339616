import { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { gql } from '@apollo/client'

import client from 'api/client'

const QUERY = gql`
	query {
		allFormattedSettings
	}
`

const SettingsContext = createContext()

const SettingsProvider = ({ children }) => {
	const [value, setValue] = useState(null)

	const getSettings = () => {
		client
			.query({ query: QUERY, fetchPolicy: 'no-cache' })
			.then(res => {
				setValue(res.data.allFormattedSettings || [])
			})
	}

	useEffect(getSettings, [])

	// Do not return anything until we retrieve settings
	if (!value) {
		return null
	}

	// Return a context provider
	return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
}

SettingsProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export { SettingsContext, SettingsProvider }
