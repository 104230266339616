const formatImageInput = (source = 'imageName') => {
	return value => {
		if (!value || typeof value === 'string') {
			// Value is null or the url string from the backend, wrap it in an object so the form input can handle it
			return { [source]: value }
		} else {
			// Else a new image is selected which results in a value object already having a preview link under the image key
			return value
		}
	}
}

export default formatImageInput
