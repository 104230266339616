import React from 'react'
import { Filter, TextInput } from 'react-admin'

const QueryFilter = props => (
	<Filter {...props}>
		<TextInput label="Search" source="q" alwaysOn />
	</Filter>
)

export default QueryFilter
