import React from 'react'
import dayjs from 'dayjs'
import { List, Datagrid, NumberField, TextField, EditButton, DeleteButton } from 'react-admin'
import { useMediaQuery } from '@material-ui/core'
import QueryFilter from 'components/queryFilter'

import CardList from './components/cardList'
import OfferDateField from './components/offerDateField'
import DiscountField from './components/discountField'
import RoomField from './components/roomField'

const getRecordStyle = record => {
	if (!record) return {}

	if (dayjs(record.startDate).isAfter(dayjs()) || dayjs(record.endDate).isBefore(dayjs())) {
		return { opacity: 0.38 }
	}

	return {}
}

const RoomOfferList = props => {
	const isLarge = useMediaQuery(theme => theme.breakpoints.up('md'))

	return (
		<List {...props} filters={<QueryFilter />}>
			{isLarge ? (
				<Datagrid rowStyle={getRecordStyle}>
					<NumberField source="order" />
					<TextField source="name" />
					<OfferDateField label="Offer Date" startSource="startDate" endSource="endDate" />
					<OfferDateField label="Booking Date" startSource="applyStartDate" endSource="applyEndDate" />
					<DiscountField label="Discount" />
					<RoomField label="Rooms" />
					<TextField source="shortDescription" />
					<EditButton />
					<DeleteButton />
				</Datagrid>
			) : (
				<CardList recordStyle={getRecordStyle} />
			)}
		</List>
	)
}

export default RoomOfferList
