import * as React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core'
import { CardActions, Button, TextField, CircularProgress } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles'

import theme from 'styles/theme'

const useStyles = makeStyles(
	theme => ({
		form: {
			padding: '0 1em 1em 1em',
		},
		input: {
			marginTop: '1em',
		},
		button: {
			width: '100%',
		},
		icon: {
			marginRight: theme.spacing(1),
		},
	})
)

const Input = ({
	meta: { touched, error }, // eslint-disable-line react/prop-types
	input: inputProps, // eslint-disable-line react/prop-types
	...props
}) => (
	<TextField
		error={!!(touched && error)}
		helperText={touched && error}
		{...inputProps}
		{...props}
		fullWidth
	/>
)

const LoginForm = props => {
	const { redirectTo } = props
	const [loading, setLoading] = useSafeSetState(false)
	const login = useLogin()
	const t = useTranslate()
	const notify = useNotify()
	const classes = useStyles(props)

	const validate = (values) => {
		const errors = { email: undefined, password: undefined }

		if (!values.email) {
			errors.email = t('ra.validation.required')
		}
		if (!values.password) {
			errors.password = t('ra.validation.required')
		}
		return errors
	}

	const submit = values => {
		setLoading(true)
		login(values, redirectTo)
			.then(() => {
				setLoading(false)
			})
			.catch(error => {
				setLoading(false)
				notify(
					typeof error === 'string'
						? error
						: typeof error === 'undefined' || !error.message
							? 'ra.auth.sign_in_error'
							: error.message,
					'warning',
					{
						_:
							typeof error === 'string'
								? error
								: error && error.message
									? error.message
									: undefined,
					}
				)
			})
	}

	return (
		<ThemeProvider theme={theme}>
			<Form
				onSubmit={submit}
				validate={validate}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit} noValidate>
						<div className={classes.form}>
							<div className={classes.input}>
								<Field
									autoFocus
									id="email"
									name="email"
									component={Input}
									label={t('email')}
									disabled={loading}
								/>
							</div>
							<div className={classes.input}>
								<Field
									id="password"
									name="password"
									component={Input}
									label={t('ra.auth.password')}
									type="password"
									disabled={loading}
									autoComplete="current-password"
								/>
							</div>
						</div>
						<CardActions>
							<Button
								variant="contained"
								type="submit"
								color="primary"
								disabled={loading}
								className={classes.button}
							>
								{loading && (
									<CircularProgress
										className={classes.icon}
										size={18}
										thickness={2}
									/>
								)}
								{t('ra.auth.sign_in')}
							</Button>
						</CardActions>
					</form>
				)}
			/>
		</ThemeProvider>
	)
}

LoginForm.propTypes = {
	redirectTo: PropTypes.string,
}

export default LoginForm