import { Fragment } from 'react'
import { useTranslate } from 'react-admin'
import { CircularProgress, Box, Divider } from '@material-ui/core'

import ContentForm from 'components/contentForm'

const PageContentForm = props => {
	const t = useTranslate()

	if (!props.contents) {
		return (
			<Box display="flex" justifyContent="center" m={4}>
				<CircularProgress />
			</Box>
		)
	}

	if (!props.contents.length) {
		return (
			<Box m={2} mb={3}>
				{t('contents.noneFound')}
			</Box>
		)
	}

	return props.contents.map((content, index) => (
		<Fragment key={content.id}>
			<ContentForm content={content} onUpdate={props.onUpdate} onErrorsChange={props.onErrorsChange} />
			{index < props.contents.length - 1 && (
				<Box mt={8} mb={8}>
					<Divider />
				</Box>
			)}
		</Fragment>
	))
}

export default PageContentForm
