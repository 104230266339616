import React, { useState, useEffect, useRef } from 'react'
import { useTranslate } from 'react-admin'
import { Box, TextField, Card, CardContent, CardHeader, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'

import ImageInput from 'components/imageInput'

const useStyles = makeStyles(() => ({
	cardContent: {
		paddingTop: 0
	}
}))

const ContentFormItem = (props) => {
	const t = useTranslate()
	const classes = useStyles()
	const [item, setItem] = useState(props.item)
	const timeoutRef = useRef()

	useEffect(() => {
		setItem(props.item)
	}, [props.item])

	const changeItemField = (name, value) => {
		return {
			...item,
			[name]: value
		}
	}

	const handleInputChange = e => {
		const newItem = changeItemField(e.target.name, e.target.value)
		setItem(newItem)

		clearTimeout(timeoutRef.current)
		timeoutRef.current = setTimeout(() => {
			props.onItemChange(newItem)
		}, 500)
	}

	const handleInputFocus = () => {
		if (props.onInputFocus && typeof props.onInputFocus === 'function') {
			props.onInputFocus()
		}
	}

	const handleInputBlur = () => {
		if (props.onItemChange && typeof props.onItemChange === 'function') {
			props.onItemChange(item)
		}
		if (props.onInputBlur && typeof props.onInputBlur === 'function') {
			props.onInputBlur()
		}
	}

	const handleFileChange = (name, value) => {
		const newItem = changeItemField(name, value)
		setItem(newItem)

		props.onItemChange(newItem)
	}

	const handleClickOnDeleteButton = e => {
		e.preventDefault()
		props.onItemDelete(item.id)
	}

	return (
		<Card className={props.className}>
			<CardHeader
				title={`Item #${props.index + 1}`}
				titleTypographyProps={{ variant: 'subtitle1' }}
				action={
					<IconButton
						aria-label={t('ra.action.delete')}
						onClick={handleClickOnDeleteButton}
						disabled={!props.canDelete}
					>
						<DeleteIcon />
					</IconButton>
				}
			/>
			<CardContent className={classes.cardContent}>
				{'imageName' in item ? (
					<Box mb={2}>
						<ImageInput
							name="imageName"
							value={item.imageName}
							required={props.requiredFields.includes('imageName')}
							onUpdate={handleFileChange}
							error={!!props.errors.imageName}
							helperText={props.errors.imageName}
						/>
					</Box>
				) : null}
				{'name' in item ? (
					<Box mb={2}>
						<TextField
							label="Name"
							variant="filled"
							fullWidth
							name="name"
							autoComplete="off"
							value={item.name || ''}
							onFocus={handleInputFocus}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							required={props.requiredFields.includes('name')}
							error={!!props.errors.name}
							helperText={props.errors.name}
						/>
					</Box>
				) : null}
				{'shortDescription' in item ? (
					<Box mb={2}>
						<TextField
							label="Short description"
							variant="filled"
							fullWidth
							multiline
							name="shortDescription"
							autoComplete="off"
							value={item.shortDescription || ''}
							onFocus={handleInputFocus}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							required={props.requiredFields.includes('shortDescription')}
							error={!!props.errors.shortDescription}
							helperText={props.errors.shortDescription}
						/>
					</Box>
				) : null}
				{'description' in item ? (
					<Box mb={2}>
						<TextField
							label="Description"
							variant="filled"
							fullWidth
							multiline
							name="description"
							autoComplete="off"
							value={item.description || ''}
							onFocus={handleInputFocus}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							required={props.requiredFields.includes('description')}
							error={!!props.errors.description}
							helperText={props.errors.description}
						/>
					</Box>
				) : null}
			</CardContent>
		</Card>
	)
}

ContentFormItem.defaultProps = {
	errors: {},
	requiredFields: []
}

export default ContentFormItem
