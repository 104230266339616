const flattenArray = (array, keyName, valueName = null) => {
	return array.reduce((object, item) => {
		if (typeof item[keyName] !== 'undefined') {
			object[item[keyName]] = valueName && typeof item[valueName] !== 'undefined' ? item[valueName] : item
		}
		return object
	}, {})
}

export default flattenArray
