import React from 'react'
import { Login } from 'react-admin'

import backgroundSrc from './background.jpg'
import LoginForm from './components/form'

const CustomLoginPage = () => (
	<Login backgroundImage={backgroundSrc}>
		<LoginForm />
	</Login>
)

export default CustomLoginPage
