import React, { useState, useEffect, useContext } from 'react'
import { GET_LIST } from 'react-admin'
import dayjs from 'dayjs'
import { Box, TableContainer, Paper, Table, TableRow, TableCell, TableBody } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'

import { SettingsContext } from 'contexts/settings'
import formatPrice from 'utils/formatPrice'
import getCurrencySymbol from 'utils/getCurrencySymbol'
import TextInput from 'components/textInput'
import SelectInput from 'components/selectInput'
import DataProviderContext from 'contexts/dataProvider'
import getImageUrl from 'utils/getImageUrl'

const useStyles = makeStyles(() => ({
	important: {
		fontWeight: 500,
	},
	smallInput: {
		width: '150px',
	},
	roomImage: {
		display: 'block',
		width: '32px',
		height: '32px',
		borderRadius: '50%',
	},
}))

const calculateNights = (checkIn, checkOut) => {
	if (!checkIn || !checkOut) return 0
	return dayjs(checkOut).diff(checkIn, 'day')
}

const calculateExtraGuests = (rooms, adultCount) => {
	const baseOccupancy = rooms.reduce((occupancy, room) => occupancy + room.info.baseOccupancy * room.quantity, 0)
	return adultCount > baseOccupancy ? adultCount - baseOccupancy : 0
}

const RoomForm = props => {
	const { currency, vat, serviceFees, feesPerExtraGuest } = useContext(SettingsContext)
	const dataProvider = useContext(DataProviderContext)
	const [formData, setFormData] = useState({
		rooms: [],
		feesPerExtraGuest: !isEmpty(props.record) ? props.record.feesPerExtraGuest : feesPerExtraGuest,
		vat: !isEmpty(props.record) ? props.record.vat : vat,
		serviceFees: !isEmpty(props.record) ? props.record.serviceFees : serviceFees,
	})
	const classes = useStyles()

	const init = async () => {
		const rooms = []

		const res = await dataProvider(GET_LIST, 'Room', {})
		for (const room of res.data) {
			const bookingItem = !isEmpty(props.record) ? props.record.rooms.find(input => input.info.id === room.id) : null
			rooms.push({
				info: room,
				quantity: bookingItem ? bookingItem.quantity : 0,
				unitPrice: bookingItem ? bookingItem.unitPrice : room.price,
			})
		}

		setFormData(prevFormData => ({ ...prevFormData, rooms }))
	}

	useEffect(() => init(), []) // eslint-disable-line

	const updateField = (name, value) => {
		value = +value
		setFormData(prevFormData => ({ ...prevFormData, [name]: value }))
		props.handleRecordUpdate(name, value)
	}

	const updateRoomField = (id, name, value) => {
		value = +value
		const rooms = formData.rooms.map(room => {
			if (room.info.id !== id) return room
			return {
				...room,
				[name]: value,
			}
		})

		setFormData(prevFormData => ({ ...prevFormData, rooms }))
		props.handleRecordUpdate('rooms', rooms)
	}

	const nights = calculateNights(props.startDate, props.endDate)
	const extraGuests = calculateExtraGuests(formData.rooms, props.adultCount)
	const baseAmount = nights * formData.rooms.reduce((price, room) => price + room.unitPrice * room.quantity, 0)
	const extraGuestsFeesAmount = extraGuests * formData.feesPerExtraGuest * nights
	const subtotalAmount = baseAmount + extraGuestsFeesAmount
	const vatAmount = (subtotalAmount * formData.vat) / 100
	const serviceFeesAmount = (subtotalAmount * formData.serviceFees) / 100
	const totalAmount = subtotalAmount + vatAmount + serviceFeesAmount

	const Quantity = ({ quantity, room }) => (
		<SelectInput
			name="quantity"
			value={quantity}
			label="Quantity"
			size="small"
			variant="outlined"
			onUpdate={(name, value) => updateRoomField(room.id, name, value)}
			data={Array.from(Array(room.totalQuantity + 1).keys()).map(value => ({
				name: value,
				code: value,
			}))}
		/>
	)

	const UnitPrice = ({ unitPrice, room }) => (
		<TextInput
			name="unitPrice"
			value={unitPrice}
			label="Unit price"
			onUpdate={(name, value) => updateRoomField(room.id, name, value)}
			type="number"
			size="small"
			variant="outlined"
			className={classes.smallInput}
			adornment={getCurrencySymbol(currency)}
		/>
	)

	const RoomNameAndImage = ({ room }) => (
		<Box display="flex" alignItems="center">
			<Box mr={1}>
				<img className={classes.roomImage} src={getImageUrl(room.imageName, 64, 64)} />
			</Box>
			<span>{room.name}</span>
		</Box>
	)

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableBody>
					{formData.rooms.map(({ info, quantity, unitPrice }) => (
						<TableRow key={info.id}>
							<TableCell colSpan={2}>
								<Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
									<RoomNameAndImage room={info} />
									<span>{formatPrice(unitPrice * quantity * nights, currency)}</span>
								</Box>
								<Box display="flex">
									<Box mr={1}>
										<Quantity quantity={quantity} room={info} />
									</Box>
									<UnitPrice unitPrice={unitPrice} room={info} />
								</Box>
							</TableCell>
						</TableRow>
					))}
					{baseAmount !== subtotalAmount ? (
						<TableRow>
							<TableCell className={classes.important}>Base</TableCell>
							<TableCell align="right" className={classes.important}>{formatPrice(baseAmount, currency)}</TableCell>
						</TableRow>
					) : null}
					{extraGuestsFeesAmount > 0 ? (
						<TableRow>
							<TableCell>
								<TextInput
									name="feesPerExtraGuest"
									value={formData.feesPerExtraGuest}
									label="Fees per extra guest"
									onUpdate={updateField}
									type="number"
									size="small"
									variant="outlined"
									className={classes.smallInput}
									adornment={getCurrencySymbol(currency)}
								/>
							</TableCell>
							<TableCell align="right">{formatPrice(extraGuestsFeesAmount, currency)}</TableCell>
						</TableRow>
					) : null}
					<TableRow>
						<TableCell className={classes.important}>Subtotal</TableCell>
						<TableCell align="right" className={classes.important}>
							{formatPrice(subtotalAmount, currency)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<TextInput
								name="vat"
								value={formData.vat}
								label="VAT"
								onUpdate={updateField}
								type="number"
								size="small"
								variant="outlined"
								className={classes.smallInput}
								adornment="%"
							/>
						</TableCell>
						<TableCell align="right">{formatPrice(vatAmount, currency)}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<TextInput
								name="serviceFees"
								value={formData.serviceFees}
								label="Service fees"
								onUpdate={updateField}
								type="number"
								size="small"
								variant="outlined"
								className={classes.smallInput}
								adornment="%"
							/>
						</TableCell>
						<TableCell align="right">{formatPrice(serviceFeesAmount, currency)}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className={classes.important}>Total</TableCell>
						<TableCell align="right" className={classes.important}>
							{formatPrice(totalAmount, currency)}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default RoomForm
