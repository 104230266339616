import React from 'react'
import {
	TextInput,
	DateInput,
	NumberInput,
	RadioButtonGroupInput,
	ReferenceArrayInput,
	CheckboxGroupInput,
	ImageInput,
	number,
	minValue,
	required,
} from 'react-admin'
import { Typography, Box, Grid } from '@material-ui/core'

import PreviewImage from 'components/previewImage'
import formatImageInput from 'utils/formatImageInput'

const SanitizedCheckboxGroupInput = props => {
	const { error, ...rest } = props  // eslint-disable-line no-unused-vars
	return <CheckboxGroupInput {...rest} />
}

const Form = () => {
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Box mb={2}>
						<Box mb={2}>
							<Typography variant="h5" component="p">
								General information
							</Typography>
						</Box>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextInput
									source="name"
									fullWidth
									helperText="Name which is displayed in the website"
									validate={required()}
								/>
							</Grid>
							<Grid item xs={6} sm={6}>
								<NumberInput
									source="order"
									min={0}
									max={1000}
									fullWidth
									helperText="Put all offers in order. Higher values will be displayed first."
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<NumberInput
									source="minimumNight"
									helperText="Fill the minimum nights needed for this offer"
									min={0}
									initialValue={0}
									validate={[number(), minValue(0)]}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<RadioButtonGroupInput
									source="discountType"
									choices={[
										{ id: 'percent', name: 'Percent' },
										{ id: 'flat', name: 'Flat' },
									]}
									initialValue="percent"
									validate={required()}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<NumberInput
									source="discount"
									fullWidth
									helperText="Amount of your discount"
									min={0}
									validate={[required(), number(), minValue(0)]}
								/>
							</Grid>
							<Grid item xs={12}>
								<ReferenceArrayInput
									source="roomsIds"
									helperText="Select all the rooms where this offer will be apply"
									resource="RoomOffer"
									reference="Room"
									validate={required()}
								>
									<SanitizedCheckboxGroupInput label="Rooms" />
								</ReferenceArrayInput>
							</Grid>
						</Grid>
					</Box>
					<Box mb={2}>
						<Box mb={2}>
							<Typography variant="h5" component="p">
								Descriptions
							</Typography>
						</Box>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextInput source="shortDescription" multiline fullWidth validate={required()} />
							</Grid>
							<Grid item xs={12}>
								<TextInput source="description" multiline fullWidth />
							</Grid>
						</Grid>
						<Box mb={2}>
							<Typography variant="h5" component="p">
								Dates
							</Typography>
						</Box>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="body1" component="p">
									Choose the starting and ending dates of your offer:
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={6} sm={6}>
										<DateInput source="startDate" fullWidth validate={required()} />
									</Grid>
									<Grid item xs={6} sm={6}>
										<DateInput source="endDate" fullWidth validate={required()} />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body1" component="p">
									Choose for which booking period this offer should be apply:
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={6} sm={6}>
										<DateInput source="applyStartDate" fullWidth validate={required()} />
									</Grid>
									<Grid item xs={6} sm={6}>
										<DateInput source="applyEndDate" fullWidth validate={required()} />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<ImageInput source="imageName" format={formatImageInput()} accept="image/*">
						<PreviewImage source="imageName" />
					</ImageInput>
				</Grid>
			</Grid>
		</>
	)
}

export default Form
