import { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import Quill from 'quill'
import { makeStyles } from '@material-ui/core/styles'

import './styles.css'

const useStyles = makeStyles(() => ({
	wrapper: {
		width: '100%',
	},
}))

const RichTextInput = ({ name, value, onUpdate, options, toolbar, ...rest }) => {
	const quillInstance = useRef()
	const ref = useRef()
	const editor = useRef()
	const lastValueChange = useRef(value)
	const classes = useStyles()

	const onChange = useCallback(
		debounce(() => {
			const value = editor.current.innerHTML === '<p><br></p>' ? '' : editor.current.innerHTML

			if (lastValueChange.current !== value) {
				lastValueChange.current = value
				onUpdate(name, value)
			}
		}, 500),
		[onUpdate]
	)

	useEffect(() => {
		quillInstance.current = new Quill(ref.current, {
			modules: { toolbar, clipboard: { matchVisual: false } },
			theme: 'snow',
			...options,
		})
		quillInstance.current.setContents(quillInstance.current.clipboard.convert(value))

		editor.current = ref.current.querySelector('.ql-editor')
		quillInstance.current.on('text-change', onChange)

		return () => {
			quillInstance.current.off('text-change', onChange)
			quillInstance.current = null
		}
	}, [])

	return <div ref={ref} className={classes.wrapper} {...rest} />
}

RichTextInput.defaultProps = {
	options: {},
	toolbar: true,
}

RichTextInput.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	onUpdate: PropTypes.func,
	options: PropTypes.object,
	toolbar: PropTypes.bool,
}

export default RichTextInput
