import React from 'react'
import { connect } from 'react-redux'
import { showNotification } from 'react-admin'
import { LinearProgress, Box, Typography } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'

import TextInput from 'components/textInput'
import ImageInput from 'components/imageInput'
import ContentForm from 'components/contentForm'
import Form from 'components/form'
import useForm from 'hooks/useForm'
import useFormStyles from 'hooks/useFormStyles'
import usePageContent from 'hooks/usePageContent'
import flattenArray from 'utils/flattenArray'

const title = 'Room homepage'

const resources = [
	{
		resource: 'Text',
		filter: { slugs: ['roomMainTitle', 'roomMainDescription', 'roomInclusivesTitle', 'roomFacilityTitle'] },
	},
	{ resource: 'Image', filter: { slugs: ['homeRoom'] } },
]

const RoomPageForm = props => {
	const classes = useFormStyles()
	const { data, hasChanges, update, submit } = useForm(resources)
	const { contents, updateContent, updateContentErrors, submitContents, hasContentChanges, hasContentErrors } = usePageContent('rooms')

	const handleSubmit = async () => {
		if (hasChanges) {
			await submit()
		}
		if (hasContentChanges) {
			await submitContents()
		}
		props.showNotification('Page saved!')
	}

	if (isEmpty(data)) {
		return (
			<Form title={title} disableSaveButton={true}>
				<LinearProgress />
			</Form>
		)
	}

	const texts = flattenArray(data.Text, 'slug')
	const images = flattenArray(data.Image, 'slug')

	return (
		<Form title={title} disableSaveButton={!hasChanges && !hasContentChanges || hasContentErrors} onSubmit={handleSubmit}>
			<Box mb={2}>
				<Typography variant="h5" component="h2">
					Introduction
				</Typography>
			</Box>
			<Box mb={2}>
				<ImageInput
					label="Poster image"
					name="imageName"
					value={images.homeRoom.imageName}
					onUpdate={(name, value) => update('Image', images.homeRoom.id, { [name]: value })}
					required
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					label="Title"
					name="value"
					value={texts.roomMainTitle.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.roomMainTitle.id, { [name]: value })}
					multiline
				/>
			</Box>
			<Box mb={4}>
				<TextInput
					label="Description"
					name="value"
					value={texts.roomMainDescription.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.roomMainDescription.id, { [name]: value })}
					multiline
				/>
			</Box>
			<Box mb={4}>
				<ContentForm
					content={contents ? contents.find(content => content.type === 'slideList') : null}
					onUpdate={updateContent}
					onErrorsChange={updateContentErrors}
					title="Inclusives section - Slideshow"
					hideSectionTitles
					hideSectionInfo
				/>
			</Box>
			<Box mb={2}>
				<Typography variant="h5" component="h2">
					Inclusives section - Title
				</Typography>
			</Box>
			<Box mb={4}>
				<TextInput
					label="Title"
					name="value"
					value={texts.roomInclusivesTitle.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.roomInclusivesTitle.id, { [name]: value })}
					multiline
				/>
			</Box>
			<Box mb={2}>
				<Typography variant="h5" component="h2">
					Facility section
				</Typography>
			</Box>
			<Box>
				<TextInput
					label="Title"
					name="value"
					value={texts.roomFacilityTitle.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.roomFacilityTitle.id, { [name]: value })}
					multiline
				/>
			</Box>
		</Form>
	)
}

export default connect(null, { showNotification })(RoomPageForm)
