import { ApolloClient, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { InMemoryCache } from '@apollo/client/cache'

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_API_URI + '/graphql',
})

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('token')
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : ''
		}
	}
})

export default new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache()
})
