import React from 'react'
import { connect } from 'react-redux'
import { showNotification } from 'react-admin'
import { LinearProgress } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'

import RichTextInput from 'components/richTextInput'
// import TextInput from 'components/textInput'
// import ImageInput from 'components/imageInput'
import Form from 'components/form'
import useForm from 'hooks/useForm'
// import useFormStyles from 'hooks/useFormStyles'
import flattenArray from 'utils/flattenArray'

const title = 'Terms and Conditions'

const resources = [{ resource: 'Text', filter: { slugs: ['termsAndConditions'] } }]

const TermsAndConditionsPageForm = props => {
	// const classes = useFormStyles()
	const { data, hasChanges, update, submit } = useForm(resources)

	const handleSubmit = async () => {
		await submit()
		props.showNotification('Page saved!')
	}

	if (isEmpty(data)) {
		return (
			<Form title={title} disableSaveButton={true}>
				<LinearProgress />
			</Form>
		)
	}

	const texts = flattenArray(data.Text, 'slug')

	return (
		<Form title={title} disableSaveButton={!hasChanges} onSubmit={handleSubmit}>
			<RichTextInput
				label="Terms and Conditions"
				name="value"
				value={texts.termsAndConditions.value}
				onUpdate={(name, value) => update('Text', texts.termsAndConditions.id, { [name]: value })}
			/>
		</Form>
	)
}

export default connect(null, { showNotification })(TermsAndConditionsPageForm)
