import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import RefuseIcon from '@material-ui/icons/Close'
import { red } from '@material-ui/core/colors'

import refuseRoomBooking from 'actions/refuseRoomBooking'
import ConfirmationMailDialog from './confirmationMailDialog'

const StyledButton = styled(Button)({
	color: red[600]
})

const RefuseButton = ({ record, handleSuccess = () => {} }) => {
	const [dialogShown, setDialogShown] = useState(false)
	const [message, setMessage] = useState('We are sorry to inform you we cannot manage your booking.')

	const handleButtonClick = () => {
		setDialogShown(true)
	}

	const handleSubmit = async () => {
		setDialogShown(false)
		refuseRoomBooking(record.id, message).then(() => {
			handleSuccess()
		})
	}

	const handleMessageChange = e => {
		setMessage(e.target.value)
	}

	const handleDialogClose = () => {
		setDialogShown(false)
	}

	return <>
		<StyledButton onClick={handleButtonClick} size="small" startIcon={<RefuseIcon />}>Refuse</StyledButton>
		<ConfirmationMailDialog
			title="Refuse booking"
			open={dialogShown}
			message={message}
			handleMessageChange={handleMessageChange}
			handleSubmit={handleSubmit}
			handleClose={handleDialogClose}
		/>
	</>
}

export default RefuseButton
