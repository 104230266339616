import React from 'react'
import { Create, SimpleForm, useTranslate } from 'react-admin'

import Form from './components/form'

const Title = () => {
	const t = useTranslate()
	return <>{t('resources.User.create')}</>
}

const UserCreate = props => (
	<Create title={<Title />} {...props}>
		<SimpleForm>
			<Form />
		</SimpleForm>
	</Create>
)

export default UserCreate
