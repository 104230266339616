import React from 'react'
import { connect } from 'react-redux'
import { showNotification } from 'react-admin'
import { LinearProgress, Box, Typography } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'

import TextInput from 'components/textInput'
import ImageInput from 'components/imageInput'
import Form from 'components/form'
import ContentForm from 'components/contentForm'
import useForm from 'hooks/useForm'
import useFormStyles from 'hooks/useFormStyles'
import usePageContent from 'hooks/usePageContent'
import flattenArray from 'utils/flattenArray'

const title = 'Homepage'

const resources = [
	{
		resource: 'Text',
		filter: {
			slugs: [
				'homeMainTitle',
				'homeMainSubtitle',
				'homeMainDescription',
				'homeRoomTitle',
				'homeRoomDescription',
				'homeFacilityTitle',
				'homeFacilityDescription',
				'homeActivityTitle',
				'homeActivityDescription',
			],
		},
	},
	{ resource: 'Room' },
]

const HomePageForm = props => {
	const classes = useFormStyles()
	const { data, hasChanges, update, submit } = useForm(resources)
	const { contents, updateContent, updateContentErrors, submitContents, hasContentChanges, hasContentErrors } = usePageContent('home')

	const handleSubmit = async () => {
		if (hasChanges) {
			await submit()
		}
		if (hasContentChanges) {
			await submitContents()
		}
		props.showNotification('Page saved!')
	}

	if (isEmpty(data)) {
		return (
			<Form title={title} disableSaveButton={true}>
				<LinearProgress />
			</Form>
		)
	}

	const texts = flattenArray(data.Text, 'slug')

	return (
		<Form title={title} disableSaveButton={!hasChanges && !hasContentChanges || hasContentErrors} onSubmit={handleSubmit}>
			<Box mb={4}>
				<Box mb={2}>
					<Typography variant="h5" component="h2">Introduction</Typography>
				</Box>
				<Box mb={2}>
					<TextInput
						label="Title"
						name="value"
						value={texts.homeMainTitle.value}
						className={classes.longField}
						onUpdate={(name, value) => update('Text', texts.homeMainTitle.id, { [name]: value })}
						multiline
					/>
				</Box>
				<Box mb={2}>
					<TextInput
						label="Subtitle"
						name="value"
						value={texts.homeMainSubtitle.value}
						className={classes.longField}
						onUpdate={(name, value) => update('Text', texts.homeMainSubtitle.id, { [name]: value })}
						multiline
					/>
				</Box>
				<Box mb={4}>
					<TextInput
						label="Description"
						name="value"
						value={texts.homeMainDescription.value}
						className={classes.longField}
						onUpdate={(name, value) => update('Text', texts.homeMainDescription.id, { [name]: value })}
						multiline
					/>
				</Box>
				<Box>
					<ContentForm
						content={contents ? contents.find(content => content.type === 'slideList') : null}
						onUpdate={updateContent}
						onErrorsChange={updateContentErrors}
						title="Slideshow"
						hideSectionTitles
						hideSectionInfo
					/>
				</Box>
			</Box>
			<Box mb={4}>
				<Box mb={2}>
					<Typography variant="h5" component="h2">Room section</Typography>
				</Box>
				<Box mb={2}>
					<TextInput
						label="Title"
						name="value"
						value={texts.homeRoomTitle.value}
						className={classes.longField}
						onUpdate={(name, value) => update('Text', texts.homeRoomTitle.id, { [name]: value })}
						multiline
					/>
				</Box>
				<Box mb={4}>
					<TextInput
						label="Description"
						name="value"
						value={texts.homeRoomDescription.value}
						className={classes.longField}
						onUpdate={(name, value) => update('Text', texts.homeRoomDescription.id, { [name]: value })}
						multiline
					/>
				</Box>
				<Box mb={2}>
					<p>You can choose specific images for your homepage. Otherwise, the default room’s image will be used.</p>
				</Box>
				{data.Room.map(room => (
					<Box mb={2} key={room.id}>
						<ImageInput
							label={room.name}
							name="altImageName"
							value={room.altImageName}
							onUpdate={(name, value) => update('Room', room.id, { [name]: value })}
							required
						/>
					</Box>
				))}
			</Box>
			<Box mb={4}>
				<Box mb={2}>
					<Typography variant="h5" component="h2">Facility section</Typography>
				</Box>
				<Box mb={2}>
					<TextInput
						label="Title"
						name="value"
						value={texts.homeFacilityTitle.value}
						className={classes.longField}
						onUpdate={(name, value) => update('Text', texts.homeFacilityTitle.id, { [name]: value })}
						multiline
					/>
				</Box>
				<Box mb={2}>
					<TextInput
						label="Description"
						name="value"
						value={texts.homeFacilityDescription.value}
						className={classes.longField}
						onUpdate={(name, value) => update('Text', texts.homeFacilityDescription.id, { [name]: value })}
						multiline
					/>
				</Box>
			</Box>
			<Box>
				<Box mb={2}>
					<Typography variant="h5" component="h2">Activity section</Typography>
				</Box>
				<Box mb={2}>
					<TextInput
						label="Title"
						name="value"
						value={texts.homeActivityTitle.value}
						className={classes.longField}
						onUpdate={(name, value) => update('Text', texts.homeActivityTitle.id, { [name]: value })}
						multiline
					/>
				</Box>
				<Box>
					<TextInput
						label="Description"
						name="value"
						value={texts.homeActivityDescription.value}
						className={classes.longField}
						onUpdate={(name, value) => update('Text', texts.homeActivityDescription.id, { [name]: value })}
						multiline
					/>
				</Box>
			</Box>
		</Form>
	)
}

export default connect(null, { showNotification })(HomePageForm)
