import React from 'react'
import { SimpleForm, Create, useTranslate } from 'react-admin'

import Form from './components/form'

const Title = () => {
	const t = useTranslate()
	return <>{t('resources.RoomOffer.create')}</>
}

const RoomOfferCreate = props => (
	<Create title={<Title />} {...props}>
		<SimpleForm>
			<Form />
		</SimpleForm>
	</Create>
)

export default RoomOfferCreate
