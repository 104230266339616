import { useState, useCallback, useMemo } from 'react'
import { useTranslate } from 'ra-core'

import isEmail from 'utils/isEmail'
import isUrl from 'utils/isUrl'

const useSettingsValidator = () => {
	const [errors, setErrors] = useState({})
	const t = useTranslate()

	const validators = useMemo(() => ({
		resortName: value => {
			if (!value) return t('ra.validation.required')
			return null
		},
		resortDescription: value => {
			if (!value) return t('ra.validation.required')
			return null
		},
		resortAddress: value => {
			if (!value) return t('ra.validation.required')
			return null
		},
		currency: value => {
			if (!value) return t('ra.validation.required')
			return null
		},
		timezone: value => {
			if (!value) return t('ra.validation.required')
			return null
		},
		vat: value => {
			if (!value) return t('ra.validation.required')
			if (isNaN(value) || Number(value) < 0 || Number(value) > 100) return t('settings.validation.incorrect')
			return null
		},
		serviceFees: value => {
			if (!value) return t('ra.validation.required')
			if (isNaN(value) || Number(value) < 0 || Number(value) > 100) return t('settings.validation.incorrect')
			return null
		},
		openWeatherMapUrl: value => {
			if (value !== '' && !isUrl(value)) return t('settings.validation.url')
			return null
		},
		googleMapUrl: value => {
			if (value !== '' && !isUrl(value)) return t('settings.validation.url')
			return null
		},
		facebookUrl: value => {
			if (value !== '' && !isUrl(value)) return t('settings.validation.url')
			return null
		},
		instagramUrl: value => {
			if (value !== '' && !isUrl(value)) return t('settings.validation.url')
			return null
		},
		tripAdvisorUrl: value => {
			if (value !== '' && !isUrl(value)) return t('settings.validation.url')
			return null
		},
		contactMails: value => {
			if (value.split('\n').some(mail => !isEmail(mail))) return t('ra.validation.email')
			return null
		},
		bookingDeskEmail: value => {
			if (!value) return t('ra.validation.required')
			if (!isEmail(value)) return t('ra.validation.email')
			return null
		},
		newRoomBookingSubject: value => {
			if (!value) return t('ra.validation.required')
			return null
		},
		roomBookingConfirmationSubject: value => {
			if (!value) return t('ra.validation.required')
			return null
		},
		roomBookingCancellationSubject: value => {
			if (!value) return t('ra.validation.required')
			return null
		},
		checkInAfterHour: value => {
			if (!value) return t('ra.validation.required')
			if (isNaN(value)) return t('settings.validation.incorrect')
			return null
		},
		checkInBeforeHour: value => {
			if (!value) return t('ra.validation.required')
			if (isNaN(value)) return t('settings.validation.incorrect')
			return null
		}
	}), [t])

	const checkErrors = useCallback(settings => {
		const errors = {}
		Object.entries(settings).forEach(([name, value]) => {
			const validator = validators[name]
			if (!validator) return

			const error = validator(value)
			if (error) {
				errors[name] = error
			}
		})
		setErrors(errors)
	}, [validators])

	return {
		errors,
		checkErrors
	}
}

export default useSettingsValidator
