import React from 'react'
import { TextInput, ImageInput, NumberInput, required, useTranslate } from 'react-admin'
import { Grid } from '@material-ui/core'

import PreviewImage from 'components/previewImage'
import formatImageInput from 'utils/formatImageInput'

const Form = () => {
	const t = useTranslate()

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextInput
							source="name"
							helperText="Name which is displayed in the website."
							fullWidth
							multiline
							validate={required()}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<NumberInput
							source="order"
							min={0}
							max={1000}
							fullWidth
							helperText={t('orderHelperText')}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextInput source="shortDescription" helperText="Few words about the activity." multiline fullWidth />
					</Grid>
					<Grid item xs={12} xl={6}>
						<TextInput source="description" helperText="Full description of the activity." multiline fullWidth />
					</Grid>
					<Grid item xs={12} xl={6}>
						<TextInput source="content" helperText="Content of the activity." multiline fullWidth />
					</Grid>
					<Grid item xs={12} sm={6}>
						<NumberInput
							source="pricePerPax"
							helperText="Price for one person, using the currency defined in settings."
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<NumberInput
							source="minPax"
							fullWidth
							helperText="Indicates how many people should apply for this activity at minimum."
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} md={6}>
				<ImageInput source="imageName" format={formatImageInput()} validate={required()} accept="image/*">
					<PreviewImage source="imageName" />
				</ImageInput>
			</Grid>
		</Grid>
	)
}

export default Form
