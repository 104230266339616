import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'

import englishAppMessages from 'i18n/en'

const messages = {
	en: { ...englishMessages, ...englishAppMessages }
}

export default polyglotI18nProvider(locale => messages[locale], 'en', { allowMissing: true })
