import React from 'react'
import { Create, useTranslate } from 'react-admin'

import Form from './components/form'

const Title = () => {
	const t = useTranslate()
	return <>{t('resources.RoomBooking.create')}</>
}

const RoomBookingCreate = props => (
	<Create title={<Title />} {...props}>
		<Form />
	</Create>
)

export default RoomBookingCreate
