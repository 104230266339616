import React from 'react'
import { Route } from 'react-router-dom'

import Settings from 'pages/settings'
import Inclusives from 'pages/inclusives'
import EmailingForm from 'pages/emailingForm'
import HomePageForm from 'pages/homePageForm'
import RoomPageForm from 'pages/roomPageForm'
import FacilityPageForm from 'pages/facilityPageForm'
import ActivityPageForm from 'pages/activityPageForm'
import Profile from 'pages/profile'
import TermsAndConditionsPageForm from 'pages/termsAndConditionsPageForm'

const customRoutes = [
	<Route key={0} path="/Settings" component={Settings} />,
	<Route key={1} path="/Inclusives" component={Inclusives} />,
	<Route key={2} path="/Emailing" component={EmailingForm} />,
	<Route key={3} path="/Pages/home" component={HomePageForm} />,
	<Route key={4} path="/Pages/room" component={RoomPageForm} />,
	<Route key={5} path="/Pages/facility" component={FacilityPageForm} />,
	<Route key={6} path="/Pages/activity" component={ActivityPageForm} />,
	<Route key={7} path="/Pages/termsAndConditions" component={TermsAndConditionsPageForm} />,
	<Route key={8} path="/Profile" component={Profile} />,
]

export default customRoutes
