import React from 'react'
import { useTranslate } from 'react-admin'
import classnames from 'classnames'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ContentSave from '@material-ui/icons/Save'

const useStyles = makeStyles(
	theme => ({
		leftIcon: {
			marginRight: theme.spacing(1),
		},
		icon: {
			fontSize: 18,
		},
	})
)

const SaveButton = ({ onClick, disabled }) => {
	const classes = useStyles()
	const t = useTranslate()

	return (
		<Button variant="contained" color="primary" onClick={onClick} disabled={disabled}>
			<ContentSave className={classnames(classes.leftIcon, classes.icon)} /> {t('ra.action.save')}
		</Button>
	)
}

export default SaveButton
