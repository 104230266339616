import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
	palette: {
		primary: { main: '#0072bc' },
		secondary: { main: '#0072bc' },
		background: { default: '#f3f4f4', lighter: '#fafafa' },
		text: { primary: '#1b2324', secondary: '#484e4f', tertiary: '#767b7b' },
	},
	typography: {
		fontFamily: 'inherit',
	},
})

theme.overrides = {
	MuiTableCell: {
		head: {
			whiteSpace: 'nowrap',
		},
		sizeSmall: {
			paddingLeft: theme.spacing(1.5),
			paddingRight: theme.spacing(1.5),
			'&:last-child': {
				paddingRight: theme.spacing(1.5),
			},
		},
	},
	MuiFilledInput: {
		root: {
			backgroundColor: '#f5f5f5',
		},
	},
	MuiTab: {
		wrapper: {
			flexDirection: 'row',
		},
	},
	MuiTypography: {
		h5: {
			fontFamily: 'FjallaOne',
			fontSize: '1.125rem',
		},
		h6: {
			fontFamily: 'FjallaOne',
			fontSize: '1rem',
		},
	},
	MuiInputAdornment: {
		root: {
			'& button': {
				padding: 0,
			},
		},
	},
	MuiTooltip: {
		popper: {
			display: 'none' // @TODO: put it back when it's not fucked up anymore
		}
	},
	RaCheckboxGroupInput: {
		label: {
			transform: 'translate(0, 0) scale(0.75)',
		},
	},
	RaEdit: {
		noActions: {
			marginTop: 0,
		},
	},
}

export default theme
