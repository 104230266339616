import React from 'react'
import { SimpleForm, Edit, useTranslate } from 'react-admin'

import Form from './components/form'

const Title = ({ record }) => {
	const t = useTranslate()
	return <>{record ? t('resources.RoomOffer.edit', { name: record.name }) : t('resources.RoomOffer.name')}</>
}

const RoomOfferEdit = props => {
	return (
		<Edit title={<Title />} undoable={false} {...props}>
			<SimpleForm>
				<Form />
			</SimpleForm>
		</Edit>
	)
}

export default RoomOfferEdit