import React from 'react'
import { Edit, useTranslate } from 'react-admin'

import Form from './components/form'

const Title = ({ record }) => {
	const t = useTranslate()
	return <>{record ? t('resources.RoomBooking.edit', { number: record.number }) : t('resources.RoomBooking.name')}</>
}

const RoomBookingEdit = props => (
	<Edit title={<Title />} undoable={false} {...props}>
		<Form />
	</Edit>
)

export default RoomBookingEdit
