const getPageSlug = (resource, record) => {
	switch (resource) {
		case 'Activity':
			return `activities/${record.slug}`
		case 'Facility':
			return `facilities/${record.slug}`
		case 'Room':
			return `rooms/${record.slug}`
		default:
			return null
	}
}

export default getPageSlug
