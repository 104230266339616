import { useState, useContext } from 'react'
import { useTranslate } from 'react-admin'
import { FormControl, FormControlLabel, FormHelperText, Switch } from '@material-ui/core'

import { SettingsContext } from 'contexts/settings'

const NotificationSwitcher = () => {
	const t = useTranslate()
	// Determines if notifications are enabled
	const [enabled, setEnabled] = useState(JSON.parse(localStorage.getItem('notifications')))
	// Determines if the user has disabled notifications
	const [permission, setPermission] = useState(Notification.permission)
	// Determines if the browser supports service workers (and notifications)
	const unavailable = !('serviceWorker' in navigator)
	// Retrieve the VAPID public key for the subscription
	const { vapidPublicKey } = useContext(SettingsContext)

	const changeState = enabled => {
		localStorage.setItem('notifications', enabled)
		setEnabled(enabled)
	}

	const enable = () => {
		navigator.serviceWorker.controller.postMessage({
			action: 'subscribe',
			payload: {
				url: process.env.REACT_APP_API_URI,
				key: vapidPublicKey,
			},
		})
		changeState(true)
	}

	const disable = () => {
		navigator.serviceWorker.controller.postMessage({
			action: 'unsubscribe',
			payload: {
				url: process.env.REACT_APP_API_URI,
				key: vapidPublicKey,
			},
		})
		changeState(false)
	}

	const toggle = e => {
		if (e.target.checked) {
			Notification.requestPermission(permission => {
				if (permission === 'granted') {
					enable()
				}
				setPermission(permission)
			})
		} else {
			disable()
		}
	}

	return (
		<FormControl>
			<FormControlLabel
				label={enabled ? t('profile.notifications.enabled') : t('profile.notifications.disabled')}
				control={<Switch checked={enabled && permission === 'granted'} onChange={toggle} />}
				disabled={unavailable || permission === 'denied'}
			/>
			{permission === 'denied' ? (
				<FormHelperText>{t('profile.notifications.errors.denied')}</FormHelperText>
			) : unavailable ? (
				<FormHelperText>{t('profile.notifications.errors.unavailable')}</FormHelperText>
			) : null}
		</FormControl>
	)
}

export default NotificationSwitcher
