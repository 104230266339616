import React from 'react'
import { SingleFieldList, ReferenceArrayField, ChipField } from 'react-admin'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	wrapper: {
		minWidth: '150px',
	},
})

const Chip = props => {
	if (!props.record) return null

	// Keep only the initial letters
	const record = { ...props.record }
	record.shortName = record.name
		.split(' ')
		.map(word => word[0])
		.join('')

	return <ChipField source="shortName" record={record} />
}

const RoomField = props => {
	const classes = useStyles()

	return (
		<Box className={classes.wrapper}>
			<ReferenceArrayField {...props} source="roomsIds" reference="Room">
				<SingleFieldList>
					<Chip />
				</SingleFieldList>
			</ReferenceArrayField>
		</Box>
	)
}

export default RoomField
