import { gql } from '@apollo/client'

import client from 'api/client'

const MUTATION = gql`
	mutation acceptRoomBooking($id: UUID!, $message: String) {
		acceptRoomBooking(id: $id, message: $message) {
			status
		}
	}
`

const acceptRoomBooking = async (id, message) => {
	const res = await client.mutate({
		mutation: MUTATION,
		variables: { id, message }
	})
	return res.data
}

export default acceptRoomBooking
