import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import getImageUrl from 'utils/getImageUrl'

const useStyles = makeStyles({
	image: {
		display: 'block',
		width: '100%'
	}
})

const PreviewImage = ({ record, source }) => {
	const classes = useStyles()

	if (!record[source]) {
		return null
	}

	const src = getImageUrl(record[source], 768, 576)

	return <img className={classes.image} src={src} alt="Preview" />
}

export default PreviewImage
