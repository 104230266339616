import React from 'react'
import { useTranslate } from 'react-admin'
import { Typography, Button, Box, withWidth } from '@material-ui/core'

const ContentForm = props => {
	const t = useTranslate()

	const handleClickOnAddButton = e => {
		e.preventDefault()
		props.handleAddItem()
	}

	return (
		<Box display="flex" justifyContent="space-between" alignItems="center">
			<Box>
				<Typography variant="h5" component="h2">
					{props.title ?? t(`contents.${props.content.type}.title`)}
				</Typography>
				<Typography variant="body1" component="h3">
					{t(`contents.${props.content.type}.description`)}
				</Typography>
			</Box>
			<Box ml={2}>
				<Button
					onClick={handleClickOnAddButton}
					variant="outlined"
					color="primary"
					disabled={props.content.items.length === props.content.max}
				>
					{t('actions.addItem')}
				</Button>
			</Box>
		</Box>
	)
}

export default withWidth()(ContentForm)
