import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-admin'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { SettingsBackupRestore as ResetIcon } from '@material-ui/icons'

const TextInput = ({ onUpdate, error, helperText, ...props }) => {
	const t = useTranslate()
	const initialValue = useRef(String(props.value))
	const [value, setValue] = useState(String(props.value))
	const timeoutRef = useRef()

	const handleChange = useCallback(e => {
		setValue(e.target.value)

		clearTimeout(timeoutRef.current)
		timeoutRef.current = setTimeout(() => {
			onUpdate(props.name, props.type === 'number' ? +e.target.value : e.target.value)
		}, 500)
	}, [onUpdate])

	const handleBlur = () => {
		clearTimeout(timeoutRef.current)
		onUpdate(props.name, props.type === 'number' ? +value : value)
	}

	const handleClickOnReset = () => {
		setValue(initialValue.current)
		onUpdate(props.name, initialValue.current)
	}

	const getStartAdornment = () => {
		if (!props.adornment) return null
		return <InputAdornment position="start">{props.adornment}</InputAdornment>
	}

	const getEndAdornment = () => {
		return (
			<InputAdornment position="end">
				<IconButton
					disabled={value === initialValue.current}
					aria-label={t('ra.action.undo')}
					onClick={handleClickOnReset}
				>
					<ResetIcon />
				</IconButton>
			</InputAdornment>
		)
	}

	// Force error display if the field is required but the value is empty
	if (props.required && (value === null || value === '') && !error) {
		error = true
		helperText = t('ra.validation.required')
	}

	return (
		<TextField
			{...props}
			value={value || ''}
			onChange={handleChange}
			onBlur={handleBlur}
			error={error}
			helperText={helperText}
			autoComplete="off"
			InputProps={{
				startAdornment: getStartAdornment(),
				endAdornment: getEndAdornment(),
			}}
		/>
	)
}

TextInput.defaultProps = {
	adornment: '',
	variant: 'filled',
	size: 'medium',
}

TextInput.propTypes = {
	name: PropTypes.string.isRequired,
	adornment: PropTypes.any,
	onChange: PropTypes.func,
}

export default TextInput
