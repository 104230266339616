import React, { useState, useRef } from 'react'
import classnames from 'classnames'
import { useTranslate } from 'react-admin'
import { Button, IconButton, InputLabel, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RemoveIcon from '@material-ui/icons/Delete'

import convertFileToBase64 from 'utils/convertFileToBase64'
import getImageUrl from 'utils/getImageUrl'

const useStyles = makeStyles(theme => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '450px'
	},
	fullWidth: {
		width: '100%',
	},
	inner: {
		backgroundColor: theme.palette.background.default,
		borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
		borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
		padding: theme.spacing(1.5),
	},
	imageWrapper: {
		position: 'relative',
	},
	image: {
		paddingBottom: '50%',
		backgroundSize: '100% auto',
		backgroundPosition: 'center center',
		cursor: 'pointer',
	},
	label: {
		marginBottom: theme.spacing(1),
	},
	input: {
		display: 'none',
	},
	helperText: {
		marginLeft: theme.spacing(1.75),
		marginRight: theme.spacing(1.75),
	},
	removeButton: {
		position: 'absolute',
		top: 0,
		right: 0,
		color: theme.palette.error.main,
	},
}))

const Preview = props => {
	const classes = useStyles()
	return <div className={classes.image} style={{ backgroundImage: `url(${props.src})` }}></div>
}

const RemoveButton = props => {
	const t = useTranslate()
	const classes = useStyles()

	return (
		<IconButton
			className={classes.removeButton}
			onClick={props.onClick}
			aria-label={t('ra.action.delete')}
			title={t('ra.action.delete')}
		>
			<RemoveIcon className={classes.removeIcon} />
		</IconButton>
	)
}

const ImageInput = props => {
	const t = useTranslate()
	const classes = useStyles()
	const [src, setSrc] = useState(props.value ? getImageUrl(props.value, 640, 320) : null)
	const id = useRef('imageName-' + Math.random().toString(36).substr(2))

	const handleChange = e => {
		if (!e.target.files || !e.target.files.length) {
			return
		}

		// Update the src image to show a preview
		setSrc(URL.createObjectURL(e.target.files[0]))

		// Convert it to base64 and notify the parent component
		convertFileToBase64(e.target.files[0]).then(base64 => {
			if (props.onUpdate && typeof props.onUpdate === 'function') {
				props.onUpdate(props.name.replace('Name', 'Base64'), base64)
			}
		})
	}

	const handleDelete = e => {
		e.preventDefault()
		e.stopPropagation()

		setSrc(null)
		props.onUpdate(props.name.replace('Name', 'Base64'), null)
	}

	return (
		<div className={classnames(classes.wrapper, props.fullWidth ? classes.fullWidth : null)}>
			<label htmlFor={id.current} className={classes.inner}>
				<InputLabel shrink={true} className={classes.label} required={props.required} error={props.error}>
					{props.label}
				</InputLabel>
				{src ? (
					<div className={classes.imageWrapper}>
						<Preview src={src} />
						{!props.required ? <RemoveButton onClick={handleDelete} /> : null}
					</div>
				) : (
					<Button variant="contained" component="span">
						{t('actions.selectFile')}
					</Button>
				)}
				<input
					type="file"
					accept="image/*"
					id={id.current}
					className={classes.input}
					name={props.imageName}
					onChange={handleChange}
				/>
			</label>
			{props.helperText && (
				<FormHelperText error={props.error} className={classes.helperText}>
					{props.helperText}
				</FormHelperText>
			)}
		</div>
	)
}

ImageInput.defaultProps = {
	label: 'Image',
	required: false,
	error: false,
}

export default ImageInput
