import React from 'react'
import { Edit, SimpleForm, useTranslate } from 'react-admin'

import Form from './components/form'

const Title = ({ record }) => {
	const t = useTranslate()
	return (
		<>
			{record ? t('resources.User.edit', { name: record.firstName + ' ' + record.lastName }) : t('resources.User.name')}
		</>
	)
}

const UserEdit = props => (
	<Edit title={<Title />} undoable={false} {...props}>
		<SimpleForm>
			<Form />
		</SimpleForm>
	</Edit>
)

export default UserEdit
