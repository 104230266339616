const en = {
	resources: {
		Text: {
			fields: {
				id: 'Key',
				value: 'Text content',
			},
		},
		RoomBooking: {
			name: 'Bookings',
			create: 'Create a new booking',
			edit: 'Edit booking - %{number}',
			fields: {
				startDate: 'Check-in',
				endDate: 'Check-out',
				adultCount: 'Adults',
				childCount: 'Children',
				createdAt: 'Reservation date',
				message: 'Special request',
			},
		},
		RoomOffer: {
			name: 'Offers',
			create: 'Create a new room offer',
			edit: 'Edit room offer - %{name}',
		},
		User: {
			name: 'Users',
			create: 'Create a new user',
			edit: 'Edit user - %{name}',
		},
		Room: {
			name: 'Rooms',
			edit: 'Edit room - %{name}',
		},
		Activity: {
			name: 'Activities',
			create: 'Create a new activity',
			edit: 'Edit activity - %{name}',
		},
		Facility: {
			name: 'Facilities',
			create: 'Create a new facility',
			edit: 'Edit facility - %{name}',
		},
		Photo: {
			name: 'Photos',
			fields: {
				entityType: 'Category',
				entityId: 'Target',
			},
			filters: {
				entityTypes: 'Categories',
			},
		},
	},
	email: 'Email',
	adultCount: '%{smart_count} adult |||| %{smart_count} adults',
	childCount: '%{smart_count} child |||| %{smart_count} children',
	orderHelperText: 'Determines how it is displayed compared to others (higher values first).',
	contents: {
		noneFound: 'No content found for this page.',
		blockList: {
			title: 'Blocks',
			description: 'A list of large contents, with a title, a description and a side image.',
		},
		cardList: {
			title: 'Cards',
			description: 'A set of illustrative cards, with an image and a description.',
		},
		descriptionList: {
			title: 'Descriptions',
			description: 'A list of descriptive texts.',
		},
		slideList: {
			title: 'Slideshow',
			description: 'A set of large illustrations in a slideshow.',
		},
	},
	settings: {
		name: 'Settings',
		title: {
			establishmentInfo: 'Establishment information',
			bookingFees: 'Booking fees',
			externalLinks: 'External links',
			contactInfo: 'Contact information',
		},
		list: {
			resortName: {
				label: 'Name',
				description: "Establishment's name.",
			},
			resortDescription: {
				label: 'Description',
				description: "Establishment's description, used for Search engines.",
			},
			resortAddress: {
				label: 'Address',
				description: "Establishment's complete address.",
			},
			timezone: {
				label: 'Time zone',
				description: "Establishment's time zone, used to calculate bookings and display clock.",
			},
			currency: {
				label: 'Currency',
				description: 'Currency used by the establishment, used to display price units.',
			},
			vat: {
				label: 'VAT',
				description: 'Value-added tax applied to bookings.',
			},
			serviceFees: {
				label: 'Service fees',
				description: 'Service fees applied to bookings.',
			},
			feesPerExtraGuest: {
				label: 'Fees per additional guest',
				description: 'Fees per additional adult guests above the room base occupancy.',
			},
			openWeatherMapUrl: {
				label: 'Open Weather Map URL',
				description: 'URL to Open Weather Map, used to display weather information.',
			},
			googleMapUrl: {
				label: 'Google Maps URL',
				description: "URL to Google Maps containing establishment's coordinates.",
			},
			facebookUrl: {
				label: 'Facebook URL',
				description: "URL to establishment's Facebook page.",
			},
			instagramUrl: {
				label: 'Instagram URL',
				description: "URL to establishment's Instagram page.",
			},
			tripAdvisorUrl: {
				label: 'Trip Advisor URL',
				description: "URL to establishment's Trip Advisor page.",
			},
			contactMail: {
				label: 'Contact mail',
				description: 'Contact mail address.',
			},
			careerMail: {
				label: 'Career mail',
				description: 'Career mail address.',
			},
			contactPhone: {
				label: 'Contact phone',
				description: 'Contact phone number.',
			},
			bookingDeskEmail: {
				label: 'Booking email',
				description: 'Email address in which booking request will be sent.',
			},
			newRoomBookingSubject: {
				label: 'New booking email subject',
				description: 'Subject of the email which is sent when a booking is made on the website.',
			},
			roomBookingConfirmationSubject: {
				label: 'Confirmation booking email subject',
				description: 'Subject of the email which is sent when a booking is confirmed.',
			},
			roomBookingCancellationSubject: {
				label: 'Cancellation email subject',
				description: 'Subject of the email which is sent when a booking is cancelled.',
			},
			checkInAfterHour: {
				label: 'Check-in hour',
				description: 'Check-in only after this time',
			},
			checkOutBeforeHour: {
				label: 'Check-out hour',
				description: 'Check-out only before this time',
			},
		},
		validation: {
			incorrect: 'Incorrect value',
			url: 'Must be a valid URL',
		},
	},
	inclusives: {
		name: 'Inclusives',
	},
	emailing: {
		name: 'Emailing',
	},
	inclusiveCategory: {
		room: 'Room',
		servicesAndAmenities: 'Services & Amenities',
		relaxation: 'Relaxation',
		activities: 'Activities',
		cateringAndSnacks: 'Catering & Snacks',
		mobility: 'Mobility',
	},
	profile: {
		name: 'Profile',
		title: '%{firstName}\'s profile',
		notifications: {
			label: 'Notifications',
			enabled: 'Enabled',
			disabled: 'Disabled',
			errors: {
				denied: 'Notifications are disabled on this page by your browser.',
				unavailable: 'Notifications are not available on your browser.',
			},
		},
		password: {
			label: 'Change password',
			fieldLabel: 'New password',
			success: 'Password changed!',
			error: 'Oops, something went wrong, try again later.',
		},
	},
	actions: {
		addItem: 'Add an element',
		selectFile: 'Select a file',
	},
}

export default en
