import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import classnames from 'classnames'
import { Typography, Grid, Box, withWidth } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import useEffectAfterMounted from 'hooks/useEffectAfterMounted'

import { createItem, getRequiredItemFields, updateItemsOrder } from './lib'
import { Header, Fields, Items } from './components'

const useStyles = makeStyles(() => ({
	wrapper: {
		touchAction: 'none',
	},
}))

const ContentForm = props => {
	const classes = useStyles()
	const t = useTranslate()
	const [content, setContent] = useState(props.content)
	const [errors, setErrors] = useState({})

	const addItem = () => {
		let items = [...content.items]
		items.push(createItem(props.content.type))
		items = updateItemsOrder(items)

		setContent({
			...content,
			items,
		})
	}

	const updateItem = newItem => {
		const items = content.items.map(item => (item.id === newItem.id ? newItem : item))

		setContent({
			...content,
			items,
		})
	}

	const updateField = (name, value) => {
		setContent({
			...content,
			[name]: value,
		})
	}

	const deleteItem = itemId => {
		let items = content.items.filter(item => item.id !== itemId)
		items = updateItemsOrder(items)

		setContent({
			...content,
			items,
		})
	}

	const moveItems = items => {
		// Adjust the order value of all items
		items = updateItemsOrder(items)

		setContent({
			...content,
			items,
		})
	}

	const checkErrors = () => {
		const errors = {}
		const requiredItemFields = getRequiredItemFields(content.type)

		for (const item of content.items) {
			for (const field of requiredItemFields) {
				if (!item[field] && (field !== 'imageName' || !item.imageBase64)) {
					errors[item.id] = {
						...(errors[item.id] || {}),
						[field]: t('ra.validation.required'),
					}
				}
			}
		}

		setErrors(errors)
	}

	useEffectAfterMounted(() => {
		checkErrors()

		if (props.onUpdate && typeof props.onUpdate === 'function') {
			props.onUpdate(content)
		}
	}, [content])

	useEffectAfterMounted(() => {
		if (props.onErrorsChange && typeof props.onErrorsChange === 'function') {
			props.onErrorsChange(content.id, errors)
		}
	}, [errors])

	if (!content) {
		return null
	}

	return (
		<>
			<Box mt={2} mb={2}>
				<Header content={content} handleAddItem={addItem} title={props.title} />
			</Box>
			<Grid container className={classnames(classes.wrapper, props.className)} spacing={4}>
				{!props.hideSectionInfo ? (
					<Grid item xs={12} md={4} lg={3}>
						{!props.hideSectionTitles ? (
							<Typography variant="h6" component="h3">
								Section information
							</Typography>
						) : null}
						<Box mt={2}>
							<Fields content={content} handleUpdateField={updateField} />
						</Box>
					</Grid>
				) : null}
				<Grid item xs={12} md={props.hideSectionInfo ? 12 : 8} lg={props.hideSectionInfo ? 12 : 9}>
					{!props.hideSectionTitles ? (
						<Typography variant="h6" component="h3">
							Section items
						</Typography>
					) : null}
					<Items
						content={content}
						errors={errors}
						handleUpdateItem={updateItem}
						handleDeleteItem={deleteItem}
						handleMoveItems={moveItems}
					/>
				</Grid>
			</Grid>
		</>
	)
}

ContentForm.defaultProps = {
	hideSectionTitles: false,
	hideSectionInfo: false,
}

export default withWidth()(ContentForm)
