import React, { useState } from 'react'
import {
	FormWithRedirect,
	SelectInput,
	DateInput,
	NumberInput,
	TextInput,
	Toolbar,
	SaveButton,
	DeleteButton,
	required,
} from 'react-admin'
import { Typography, Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import countries from 'data/countries.json'

import RoomForm from './roomForm'

const useStyles = makeStyles(() => ({
	fullWidth: {
		width: '100%',
	},
}))

const ToolbarContent = props => {
	const classes = useStyles()

	return (
		<Box display="flex" justifyContent="space-between" className={classes.fullWidth}>
			<SaveButton saving={props.saving} handleSubmitWithRedirect={props.handleSubmitWithRedirect} />
			<DeleteButton record={props.record} />
		</Box>
	)
}

const Form = props => {
	const classes = useStyles()
	const [startDate, setStartDate] = useState(props.record.startDate)
	const [endDate, setEndDate] = useState(props.record.endDate)
	const [adultCount, setAdultCount] = useState(props.record.adultCount)

	return (
		<FormWithRedirect
			{...props}
			render={formProps => (
				<form>
					<Box p={2}>
						<TextInput source="number" margin="none" validate={required()} disabled={!!formProps.record.id} />
						<Grid container spacing={4}>
							<Grid item xs={12} md={4} lg={6}>
								<Grid container className={classes.grid} spacing={2}>
									<Grid item xs={12}>
										<Typography variant="h5">
											People
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<NumberInput
											fullWidth
											margin="none"
											source="adultCount"
											label="resources.RoomBooking.fields.adultCount"
											step={1}
											validate={required()}
											onChange={e => { setAdultCount(e.target.value)}}
										/>
									</Grid>
									<Grid item xs={6}>
										<NumberInput
											fullWidth
											margin="none"
											source="childCount"
											label="resources.RoomBooking.fields.childCount"
											step={1}
											validate={required()}
										/>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="h5">
											Dates
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6} md={12} lg={6}>
										<DateInput
											fullWidth
											margin="none"
											source="startDate"
											label="resources.RoomBooking.fields.startDate"
											validate={required()}
											onChange={e => { setStartDate(e.target.value)}}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={12} lg={6}>
										<DateInput
											fullWidth
											margin="none"
											source="endDate"
											label="resources.RoomBooking.fields.endDate"
											validate={required()}
											onChange={e => { setEndDate(e.target.value)}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="h5">
											Guest information
										</Typography>
									</Grid>
									<Grid item xs={12} lg={6}>
										<TextInput margin="none" fullWidth source="firstName" validate={required()} />
									</Grid>
									<Grid item xs={12} lg={6}>
										<TextInput margin="none" fullWidth source="lastName" validate={required()} />
									</Grid>
									<Grid item xs={12} lg={6}>
										<TextInput margin="none" fullWidth source="email" validate={required()} />
									</Grid>
									<Grid item xs={12} lg={6}>
										<TextInput margin="none" fullWidth source="phone" validate={required()} />
									</Grid>
									<Grid item xs={12} lg={6}>
										<SelectInput
											fullWidth
											margin="none"
											source="country"
											validate={required()}
											choices={countries}
											optionValue="code"
										/>
									</Grid>
									<Grid item xs={12}>
										<TextInput source="message" label="resources.RoomBooking.fields.message" multiline fullWidth margin="none" />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} md={8} lg={6}>
								<Typography variant="h5">
									Booking
								</Typography>
								<Box mt={2}>
									<RoomForm
										record={formProps.record}
										handleRecordUpdate={(name, value) => formProps.form.change(name, value)}
										startDate={startDate}
										endDate={endDate}
										adultCount={adultCount}
									/>
								</Box>
							</Grid>
						</Grid>
					</Box>
					<Toolbar>
						<ToolbarContent
							saving={formProps.saving}
							handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
							record={formProps.record}
						/>
					</Toolbar>
				</form>
			)}
		/>
	)
}

export default Form
