import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import getImageUrl from 'utils/getImageUrl'

const useStyles = makeStyles(() => ({
	wrapper: {
		minWidth: '150px',
	},
	image: {
		display: 'block',
		width: '32px',
		height: '32px',
		borderRadius: '50%',
	},
}))

const ImageAndNameField = ({ record = {} }) => {
	const classes = useStyles()

	return (
		<Box display="flex" alignItems="center" className={classes.wrapper}>
			<Box mr={1}>
				<img className={classes.image} src={getImageUrl(record.imageName, 64, 64)} />
			</Box>
			<span>{record.name}</span>
		</Box>
	)
}

export default ImageAndNameField
