import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { showNotification, useTranslate } from 'react-admin'
import { LinearProgress, Box, Typography } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'

import TextInput from 'components/textInput'
import ImageInput from 'components/imageInput'
import SelectInput from 'components/selectInput'
import Form from 'components/form'
import useForm from 'hooks/useForm'
import useFormStyles from 'hooks/useFormStyles'
import useSettingsValidator from 'hooks/useSettingsValidator'
import flattenArray from 'utils/flattenArray'

const title = 'Emailing'

const resources = [
	{ resource: 'Image', filter: { slugs: ['emailHeader'] } },
	{
		resource: 'Setting',
		filter: {
			ids: [
				'bookingDeskEmail',
				'newRoomBookingSubject',
				'roomBookingConfirmationSubject',
				'roomBookingCancellationSubject',
				'checkInAfterHour',
				'checkOutBeforeHour',
			],
		},
	},
]

const FacilityPageForm = props => {
	const t = useTranslate()
	const classes = useFormStyles()
	const { data, hasChanges, update, submit } = useForm(resources)
	const { errors, checkErrors } = useSettingsValidator()
	const hasErrors = Object.keys(errors).length > 0

	const handleSubmit = async () => {
		await submit()
		props.showNotification('Settings saved!')
	}

	useEffect(() => {
		if (!data.Setting) return
		checkErrors(flattenArray(data.Setting, 'id', 'value'))
	}, [data.Setting])

	if (isEmpty(data)) {
		return (
			<Form title={title} disableSaveButton={true}>
				<LinearProgress />
			</Form>
		)
	}

	// Convert arrays into objects
	const images = flattenArray(data.Image, 'slug')
	const settings = flattenArray(data.Setting, 'id')

	return (
		<Form title={title} disableSaveButton={!hasChanges || hasErrors} onSubmit={handleSubmit}>
			<Box mb={2}>
				<Typography variant="h5" component="h2">
					General settings
				</Typography>
			</Box>
			<Box mb={2}>
				<ImageInput
					label="Top image"
					name="imageName"
					value={images.emailHeader.imageName}
					onUpdate={(name, value) => update('Image', images.emailHeader.id, { [name]: value })}
					required
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="value"
					className={classes.longField}
					value={settings.bookingDeskEmail.value}
					label={t('settings.list.bookingDeskEmail.label')}
					error={!!errors.bookingDeskEmail}
					helperText={
						errors.bookingDeskEmail ? errors.bookingDeskEmail : t('settings.list.bookingDeskEmail.description')
					}
					onUpdate={(name, value) => update('Setting', settings.bookingDeskEmail.id, { [name]: value })}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="value"
					className={classes.longField}
					value={settings.newRoomBookingSubject.value}
					label={t('settings.list.newRoomBookingSubject.label')}
					error={!!errors.newRoomBookingSubject}
					helperText={
						errors.newRoomBookingSubject
							? errors.newRoomBookingSubject
							: t('settings.list.newRoomBookingSubject.description')
					}
					onUpdate={(name, value) => update('Setting', settings.newRoomBookingSubject.id, { [name]: value })}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="value"
					className={classes.longField}
					value={settings.roomBookingConfirmationSubject.value}
					label={t('settings.list.roomBookingConfirmationSubject.label')}
					error={!!errors.roomBookingConfirmationSubject}
					helperText={
						errors.roomBookingConfirmationSubject
							? errors.roomBookingConfirmationSubject
							: t('settings.list.roomBookingConfirmationSubject.description')
					}
					onUpdate={(name, value) => update('Setting', settings.roomBookingConfirmationSubject.id, { [name]: value })}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="value"
					className={classes.longField}
					value={settings.roomBookingCancellationSubject.value}
					label={t('settings.list.roomBookingCancellationSubject.label')}
					error={!!errors.roomBookingCancellationSubject}
					helperText={
						errors.roomBookingCancellationSubject
							? errors.roomBookingCancellationSubject
							: t('settings.list.roomBookingCancellationSubject.description')
					}
					onUpdate={(name, value) => update('Setting', settings.roomBookingCancellationSubject.id, { [name]: value })}
				/>
			</Box>
			<Box mb={2}>
				<SelectInput
					name="value"
					value={settings.checkInAfterHour.value}
					label={t('settings.list.checkInAfterHour.label')}
					error={!!errors.checkInAfterHour}
					helperText={
						errors.checkInAfterHour
							? errors.checkInAfterHour
							: t('settings.list.checkInAfterHour.description')
					}
					data={[
						{ name: '10 AM', code: '10' },
						{ name: '11 AM', code: '11' },
						{ name: '12', code: '12' },
						{ name: '1 PM', code: '13' },
						{ name: '2 PM', code: '14' },
						{ name: '3 PM', code: '15' },
						{ name: '4 PM', code: '16' },
						{ name: '5 PM', code: '17' },
						{ name: '6 PM', code: '18' },
					]}
					onUpdate={(name, value) => update('Setting', settings.checkInAfterHour.id, { [name]: value })}
				/>
			</Box>
			<Box mb={2}>
				<SelectInput
					name="value"
					value={settings.checkOutBeforeHour.value}
					label={t('settings.list.checkOutBeforeHour.label')}
					error={!!errors.checkOutBeforeHour}
					helperText={
						errors.checkOutBeforeHour
							? errors.checkOutBeforeHour
							: t('settings.list.checkOutBeforeHour.description')
					}
					data={[
						{ name: '8 AM', code: '8' },
						{ name: '9 AM', code: '9' },
						{ name: '10 AM', code: '10' },
						{ name: '11 AM', code: '11' },
						{ name: '12', code: '12' },
						{ name: '1 PM', code: '13' },
						{ name: '2 PM', code: '14' },
					]}
					onUpdate={(name, value) => update('Setting', settings.checkOutBeforeHour.id, { [name]: value })}
				/>
			</Box>
		</Form>
	)
}

export default connect(null, { showNotification })(FacilityPageForm)
