import { useReducer, useEffect, useContext } from 'react'
import { GET_LIST, UPDATE } from 'react-admin'
import isEqual from 'lodash/isEqual'

import DataProviderContext from 'contexts/dataProvider'

const reducer = (state, action) => {
	switch (action.type) {
		case 'init':
			return {
				...state,
				data: action.payload,
				initialData: action.payload,
			}
		case 'updateRecord': {
			const data = {
				...state.data,
				[action.payload.resource]: state.data[action.payload.resource].map(record => {
					if (record.id === action.payload.record.id) {
						return action.payload.record
					}
					return record
				}),
			}
			return {
				...state,
				data,
				hasChanges: !isEqual(data, state.initialData),
			}
		}
		case 'submitData':
			return {
				...state,
				initialData: state.data,
				hasChanges: false,
			}
		default:
			return state
	}
}

const useForm = requestList => {
	const dataProvider = useContext(DataProviderContext)
	const [state, dispatch] = useReducer(reducer, {
		data: {},
		initialData: {},
		hasChanges: false,
	})

	const initForm = async () => {
		const data = {}
		for (const { resource, filter } of requestList) {
			const res = await dataProvider(GET_LIST, resource, { filter })
			data[resource] = res.data
		}

		dispatch({ type: 'init', payload: data })
	}

	const update = (resource, id, newData) => {
		const record = {
			...state.data[resource].find(record => record.id === id),
			...newData,
		}

		dispatch({ type: 'updateRecord', payload: { resource, record } })
	}

	const submit = async () => {
		for (const [resource, records] of Object.entries(state.data)) {
			for (const record of records) {
				const initialRecord = state.initialData[resource].find(r => r.id === record.id)
				if (!isEqual(record, initialRecord)) {
					await dataProvider(UPDATE, resource, { data: record })
				}
			}
		}
		dispatch({ type: 'submitData' })
	}

	useEffect(() => initForm(), []) // eslint-disable-line

	return {
		data: state.data,
		hasChanges: state.hasChanges,
		update,
		submit,
	}
}

export default useForm
