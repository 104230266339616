import React from 'react'
import { MenuItemLink, usePermissions, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { Box, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
	CalendarToday as RoomBookingIcon,
	Hotel as RoomIcon,
	LocalOffer as RoomOfferIcon,
	LocalBar as FacilityIcon,
	LocalActivity as ActivityIcon,
	ListAlt as PageIcon,
	Settings as SettingsIcon,
	Pool as InclusivesIcon,
	Mail as EmailingIcon,
	People as UserIcon,
} from '@material-ui/icons'

import theme from 'styles/theme'

const useStyles = makeStyles({
	title: props => ({
		whiteSpace: 'nowrap',
		color: theme.palette.text.primary,
		fontFamily: 'FjallaOne',
		opacity: props.open ? '1' : '0',
		paddingTop: props.open ? theme.spacing(2) : '0',
		paddingBottom: props.open ? theme.spacing(1) : '0',
		transition: 'opacity .4s ease, padding .4s ease'
	})
})

const Menu = () => {
	const t = useTranslate()
	const open = useSelector(state => state.admin.ui.sidebarOpen)
	const classes = useStyles({ open })
	const { permissions } = usePermissions()
	useSelector(state => state.theme) // force rerender on theme change

	return (
		<Box>
			<ListItem className={classes.title}>General</ListItem>
			<MenuItemLink
				to={`/RoomBooking`}
				primaryText={t('resources.RoomBooking.name')}
				leftIcon={<RoomBookingIcon />}
				sidebarIsOpen={open}
			/>
			<MenuItemLink
				to={`/RoomOffer`}
				primaryText={t('resources.RoomOffer.name')}
				leftIcon={<RoomOfferIcon />}
				sidebarIsOpen={open}
			/>
			<MenuItemLink
				to={`/Settings`}
				primaryText={t('settings.name')}
				leftIcon={<SettingsIcon />}
				sidebarIsOpen={open}
			/>
			<MenuItemLink
				to={`/Inclusives`}
				primaryText={t('inclusives.name')}
				leftIcon={<InclusivesIcon />}
				sidebarIsOpen={open}
			/>
			<MenuItemLink
				to={`/Emailing`}
				primaryText={t('emailing.name')}
				leftIcon={<EmailingIcon />}
				sidebarIsOpen={open}
			/>
			{permissions === 'ADMIN' ? (
				<MenuItemLink
					to={`/User`}
					primaryText={t('resources.User.name')}
					leftIcon={<UserIcon />}
					sidebarIsOpen={open}
				/>
			) : null}
			<ListItem className={classes.title}>Pages</ListItem>
			<MenuItemLink
				to={`/Pages/home`}
				primaryText={'Homepage'}
				leftIcon={<PageIcon />}
				sidebarIsOpen={open}
				mb={1}
			/>
			<MenuItemLink
				to={`/Pages/room`}
				primaryText={'Room page'}
				leftIcon={<PageIcon />}
				sidebarIsOpen={open}
				mb={1}
			/>
			<MenuItemLink
				to={`/Pages/facility`}
				primaryText={'Facility page'}
				leftIcon={<PageIcon />}
				sidebarIsOpen={open}
				mb={1}
			/>
			<MenuItemLink
				to={`/Pages/activity`}
				primaryText={'Activity page'}
				leftIcon={<PageIcon />}
				sidebarIsOpen={open}
				mb={1}
			/>
			<MenuItemLink
				to={`/Pages/termsAndConditions`}
				primaryText={'Terms and Conditions'}
				leftIcon={<PageIcon />}
				sidebarIsOpen={open}
				mb={1}
			/>
			<ListItem className={classes.title}>Dynamic contents</ListItem>
			<MenuItemLink
				to={`/Room`}
				primaryText={t('resources.Room.name')}
				leftIcon={<RoomIcon />}
				sidebarIsOpen={open}
			/>
			<MenuItemLink
				to={`/Activity`}
				primaryText={t('resources.Activity.name')}
				leftIcon={<ActivityIcon />}
				sidebarIsOpen={open}
				mb={1}
			/>
			<MenuItemLink
				to={`/Facility`}
				primaryText={t('resources.Facility.name')}
				leftIcon={<FacilityIcon />}
				sidebarIsOpen={open}
				mb={1}
			/>
		</Box>
	)
}

export default Menu
