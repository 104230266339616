import React from 'react'
import { Edit, useTranslate } from 'react-admin'

import WithContentForm from 'components/withContentForm'

import Form from './components/form'

const Title = ({ record }) => {
	const t = useTranslate()
	return <>{record ? t('resources.Room.edit', { name: record.name }) : t('resources.Room.name')}</>
}

const RoomBookingEdit = props => (
	<Edit title={<Title />} undoable={false} {...props}>
		<WithContentForm>
			<Form />
		</WithContentForm>
	</Edit>
)

export default RoomBookingEdit
