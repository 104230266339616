import React from 'react'
import { connect } from 'react-redux'
import { showNotification } from 'react-admin'
import { LinearProgress, Box, Typography } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'

import TextInput from 'components/textInput'
import ImageInput from 'components/imageInput'
import Form from 'components/form'
import useForm from 'hooks/useForm'
import useFormStyles from 'hooks/useFormStyles'
import flattenArray from 'utils/flattenArray'

const title = 'Activity homepage'

const resources = [
	{
		resource: 'Text',
		filter: {
			slugs: [
				'activityMainTitle',
				'activityMainSubtitle',
				'activityMainDescription',
				'activityListTitle',
				'activityInfoTitle',
				'activityInfoDescription',
				'activityFacilityTitle',
			],
		},
	},
	{ resource: 'Image', filter: { slugs: ['homeActivity', 'homeActivityInfo'] } },
]

const ActivityPageForm = props => {
	const classes = useFormStyles()
	const { data, hasChanges, update, submit } = useForm(resources)

	const handleSubmit = async () => {
		await submit()
		props.showNotification('Page saved!')
	}

	if (isEmpty(data)) {
		return (
			<Form title={title} disableSaveButton={true}>
				<LinearProgress />
			</Form>
		)
	}

	const texts = flattenArray(data.Text, 'slug')
	const images = flattenArray(data.Image, 'slug')

	return (
		<Form title={title} disableSaveButton={!hasChanges} onSubmit={handleSubmit}>
			<Box mb={2}>
				<Typography variant="h5" component="h2">Introduction</Typography>
			</Box>
			<Box mb={2}>
				<ImageInput
					label="Poster image"
					name="imageName"
					value={images.homeActivity.imageName}
					onUpdate={(name, value) => update('Image', images.homeActivity.id, { [name]: value })}
					required
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					label="Title"
					name="value"
					value={texts.activityMainTitle.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.activityMainTitle.id, { [name]: value })}
					multiline
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					label="Subtitle"
					name="value"
					value={texts.activityMainSubtitle.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.activityMainSubtitle.id, { [name]: value })}
					multiline
				/>
			</Box>
			<Box mb={4}>
				<TextInput
					label="Description"
					name="value"
					value={texts.activityMainDescription.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.activityMainDescription.id, { [name]: value })}
					multiline
				/>
			</Box>
			<Box mb={2}>
				<Typography variant="h5" component="h2">List section</Typography>
			</Box>
			<Box mb={4}>
				<TextInput
					label="List title"
					name="value"
					value={texts.activityListTitle.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.activityListTitle.id, { [name]: value })}
					multiline
				/>
			</Box>
			<Box mb={2}>
				<Typography variant="h5" component="h2">General info section</Typography>
			</Box>
			<Box mb={2}>
				<ImageInput
					label="Info section image"
					name="imageName"
					value={images.homeActivityInfo.imageName}
					onUpdate={(name, value) => update('Image', images.homeActivityInfo.id, { [name]: value })}
					required
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					label="Info title"
					name="value"
					value={texts.activityInfoTitle.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.activityInfoTitle.id, { [name]: value })}
					multiline
				/>
			</Box>
			<Box mb={4}>
				<TextInput
					label="Info description"
					name="value"
					value={texts.activityInfoDescription.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.activityInfoDescription.id, { [name]: value })}
					multiline
				/>
			</Box>
			<Box mb={2}>
				<Typography variant="h5" component="h2">Facility section</Typography>
			</Box>
			<Box>
				<TextInput
					label="Facility title"
					name="value"
					value={texts.activityFacilityTitle.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.activityFacilityTitle.id, { [name]: value })}
					multiline
				/>
			</Box>
		</Form>
	)
}

export default connect(null, { showNotification })(ActivityPageForm)
