import React from 'react'
import { Title, Toolbar } from 'react-admin'
import { Card, CardContent } from '@material-ui/core'

import SaveButton from './components/saveButton'

const Form = props => {
	const handleClickOnSave = () => {
		props.onSubmit()
	}

	return (
		<Card>
			<Title title={props.title} />
			<CardContent>{props.children}</CardContent>
			<Toolbar>
				<SaveButton onClick={handleClickOnSave} disabled={props.disableSaveButton} />
			</Toolbar>
		</Card>
	)
}

export default Form
