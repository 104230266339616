import * as React from 'react'
import { List, Card, CardContent, CardActions, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { EditButton, DeleteButton, sanitizeListRestProps, useListContext } from 'react-admin'

const useStyles = makeStyles(theme => ({
	list: {
		backgroundColor: theme.palette.background.lighter,
		padding: theme.spacing(0.5),
	},
	card: {
		margin: theme.spacing(2),
		lineHeight: 1.5,
	},
	dateDescription: {
		color: theme.palette.text.tertiary,
	},
}))

const CardList = props => {
	const { recordStyle, ...rest } = props
	const { data, ids, loaded, total } = useListContext(props)
	const classes = useStyles(props)

	// Retrieve rest props with extra sanitization
	let restProps = sanitizeListRestProps(rest)
	delete restProps.hasBulkActions

	if (loaded === false) {
		return null
	}

	return (
		total > 0 && (
			<List className={classes.list} {...restProps}>
				{ids.map((id, index) => (
					<Card className={classes.card} style={recordStyle ? recordStyle(data[id], index) : undefined} key={index}>
						<CardContent>
							<Box mb={2}>
								<Typography variant="h6" components="h2">
									{data[id].name}
								</Typography>
							</Box>
							<Box mb={2}>
								<p>{data[id].shortDescription}</p>
								<p>{data[id].description}</p>
							</Box>
							<p className={classes.dateDescription}>
								Usable from <strong>{new Date(data[id].startDate).toLocaleDateString()}</strong> to{' '}
								<strong>{new Date(data[id].endDate).toLocaleDateString()}</strong>
							</p>
							<p className={classes.dateDescription}>
								For bookings between <strong>{new Date(data[id].applyStartDate).toLocaleDateString()}</strong> to{' '}
								<strong>{new Date(data[id].applyEndDate).toLocaleDateString()}</strong>
							</p>
						</CardContent>
						<CardActions>
							<EditButton record={data[id]} />
							<DeleteButton record={data[id]} />
						</CardActions>
					</Card>
				))}
			</List>
		)
	)
}

export default CardList
