import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
	shortField: {
		width: '100%',
		maxWidth: '200px',
	},
	longField: {
		width: '100%',
		maxWidth: '450px',
	},
})
