import React, { useState } from 'react'
import { Select } from '@material-ui/core'
import { FormControl, FormHelperText, MenuItem, InputLabel } from '@material-ui/core'

const SelectInput = (props) => {
	const {
		label,
		value: initValue,
		className,
		error,
		helperText,
		required,
		data,
		onUpdate,
		variant,
		size,
		...rest
	} = props
	const [value, setValue] = useState(initValue)

	const handleChange = e => {
		setValue(e.target.value)
		onUpdate(e.target.name, e.target.value)
	}

	return (
		<FormControl variant={variant} size={size} required={required} error={error} className={className}>
			<InputLabel shrink>{label}</InputLabel>
			<Select {...rest} value={value} label={label} onChange={handleChange}>
				{data.map(item => <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>)}
			</Select>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	)
}

SelectInput.defaultProps = {
	value: '',
	variant: 'filled',
	size: 'medium'
}

export default SelectInput
