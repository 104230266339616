import React from 'react'
import { Chip } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

import getStatusColor from '../utils/getStatusColor'

const Wrapper = styled('div')({
	textAlign: 'center',
})

const NumberChip = styled(Chip)({
	borderColor: props => props.statuscolor,
	color: props => props.statuscolor,
	textTransform: 'uppercase',
	fontWeight: 500,
})

const NumberField = ({ record = {} }) => (
	<Wrapper>
		<NumberChip variant="outlined" label={record.number} size="small" statuscolor={getStatusColor(record.status)} />
	</Wrapper>
)

export default NumberField
