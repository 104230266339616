const formatPrice = (price, currency) => {
	const CurrencyFormat = new Intl.NumberFormat('en', {
		style: 'currency',
		currency: currency,
		minimumFractionDigits: 0,
	})

	return CurrencyFormat.format(price)
}

export default formatPrice
