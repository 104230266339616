import gql from 'graphql-tag'

import client from 'api/client'

const authProvider = {
	// Called when the user attempts to log in
	login: ({ email, password }) => {
		return client.mutate({
			variables: { email, password },
			mutation: gql`
				mutation Login ($email: String!, $password: String!) {
					login(email: $email, password: $password) {
						token
					}
				}
			`
		}).then(res => {
			localStorage.setItem('token', res.data.login.token)
			return Promise.resolve()
		}).catch(error => {
			const message = error.graphQLErrors && error.graphQLErrors.length ? error.graphQLErrors.map(e => e.message)[0] : 'An error occured'
			return Promise.reject(message)
		})
	},
	// Called when the user clicks on the logout button
	logout: () => {
		localStorage.removeItem('token')
		return Promise.resolve()
	},
	// Called when the API returns an error
	checkError: ({ status }) => {
		if (status === 401 || status === 403) {
			localStorage.removeItem('token')
			return Promise.reject()
		}
		return Promise.resolve()
	},
	// Called when the user navigates to a new location, to check for authentication
	checkAuth: () => {
		return client.query({
			variables: { token: localStorage.getItem('token') || '' },
			query: gql`
				query IsTokenValid ($token: String!) {
					isTokenValid(token: $token)
				}
			`
		}).then((res) => {
			if (res.data.isTokenValid) {
				return Promise.resolve()
			} else {
				localStorage.removeItem('token')
				return Promise.reject()
			}
		}).catch(() => {
			return Promise.reject()
		})
	},
	// Called when the user navigates to a new location, to check for permissions / roles
	getPermissions: () => {
		return client.query({
			variables: { token: localStorage.getItem('token') || '' },
			query: gql`
				query GetRoleFromToken ($token: String!) {
					getRoleFromToken(token: $token)
				}
			`
		}).then((res) => {
			const role = res.data.getRoleFromToken
			if (role) {
				return Promise.resolve(role)
			} else {
				localStorage.removeItem('token')
				return Promise.reject()
			}
		}).catch(() => {
			return Promise.reject()
		})
	}
}

export default authProvider
