import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { showNotification, useTranslate } from 'react-admin'
import { LinearProgress, Box, Typography } from '@material-ui/core'
import { gql } from '@apollo/client'

import client from 'api/client'
import TextInput from 'components/textInput'
import SelectInput from 'components/selectInput'
import Form from 'components/form'
import timezones from 'data/timezones.json'
import currencies from 'data/currencies.json'
import useFormStyles from 'hooks/useFormStyles'
import useSettingsValidator from 'hooks/useSettingsValidator'

const GET_SETTINGS = gql`
	query {
		allFormattedSettings
	}
`

const UPDATE_SETTINGS = gql`
	mutation updateSettings($keyValuePairs: [SettingKeyValueInput]!) {
		updateSettingsValues(keyValuePairs: $keyValuePairs) {
			id
		}
	}
`

const SettingsPageForm = ({ showNotification }) => {
	const t = useTranslate()
	const classes = useFormStyles()
	const [settings, setSettings] = useState(null)
	const [changes, setChanges] = useState({})
	const { errors, checkErrors } = useSettingsValidator()

	const getSettings = () => {
		client
			.query({
				query: GET_SETTINGS,
				fetchPolicy: 'no-cache',
			})
			.then(res => {
				setSettings(res.data.allFormattedSettings || [])
			})
	}

	const updateSettings = changes => {
		const keyValuePairs = Object.entries(changes).map(([id, value]) => ({ id, value: String(value) }))
		client
			.mutate({
				mutation: UPDATE_SETTINGS,
				variables: { keyValuePairs },
			})
			.then(() => {
				setChanges({})
				showNotification('Settings saved.')
			})
	}

	const handleFieldUpdate = (name, value) => {
		const newChanges = { ...changes }
		if (value === settings[name]) {
			delete newChanges[name]
		} else {
			newChanges[name] = value
		}

		setChanges(newChanges)
	}

	const handleSubmit = () => {
		if (Object.keys(errors).length) return
		updateSettings(changes)
	}

	useEffect(() => {
		getSettings()
	}, [])

	useEffect(() => {
		checkErrors({ ...settings, ...changes })
	}, [settings, changes, checkErrors])

	if (!settings) {
		return (
			<Form title="Settings" disableSaveButton={true}>
				<LinearProgress />
			</Form>
		)
	}

	// Disable the save button if we have no changes yet or if we have some errors
	const disableSaveButton = Object.values(changes).length === 0 || Object.values(errors).length > 0

	return (
		<Form title="Settings" disableSaveButton={disableSaveButton} onSubmit={handleSubmit}>
			<Box mb={2}>
				<Typography variant="h5" component="h2">
					{t('settings.title.establishmentInfo')}
				</Typography>
			</Box>
			<Box mb={2}>
				<TextInput
					name="resortName"
					className={classes.longField}
					value={settings.resortName}
					label={t('settings.list.resortName.label')}
					error={!!errors.resortName}
					helperText={errors.resortName ? errors.resortName : t('settings.list.resortName.description')}
					required
					multiline
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="resortDescription"
					className={classes.longField}
					value={settings.resortDescription}
					label={t('settings.list.resortDescription.label')}
					error={!!errors.resortDescription}
					helperText={
						errors.resortDescription ? errors.resortDescription : t('settings.list.resortDescription.description')
					}
					required
					multiline
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="resortAddress"
					className={classes.longField}
					value={settings.resortAddress}
					label={t('settings.list.resortAddress.label')}
					error={!!errors.resortAddress}
					helperText={errors.resortAddress ? errors.resortAddress : t('settings.list.resortAddress.description')}
					required
					multiline
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mb={2}>
				<SelectInput
					name="timezone"
					className={classes.shortField}
					value={settings.timezone}
					data={timezones}
					label={t('settings.list.timezone.label')}
					error={!!errors.timezone}
					helperText={errors.timezone ? errors.timezone : t('settings.list.timezone.description')}
					required
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mb={2}>
				<SelectInput
					name="currency"
					className={classes.shortField}
					value={settings.currency}
					data={currencies}
					label={t('settings.list.currency.label')}
					error={!!errors.currency}
					helperText={errors.currency ? errors.currency : t('settings.list.currency.description')}
					required
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mt={4} mb={2}>
				<Typography variant="h5" component="h2">
					{t('settings.title.bookingFees')}
				</Typography>
			</Box>
			<Box mb={2}>
				<TextInput
					name="vat"
					className={classes.shortField}
					value={settings.vat}
					label={t('settings.list.vat.label')}
					error={!!errors.vat}
					helperText={errors.vat ? errors.vat : t('settings.list.vat.description')}
					required
					adornment="%"
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="serviceFees"
					className={classes.shortField}
					value={settings.serviceFees}
					label={t('settings.list.serviceFees.label')}
					error={!!errors.serviceFees}
					helperText={errors.serviceFees ? errors.serviceFees : t('settings.list.serviceFees.description')}
					required
					adornment="%"
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="feesPerExtraGuest"
					className={classes.shortField}
					value={settings.feesPerExtraGuest}
					label={t('settings.list.feesPerExtraGuest.label')}
					error={!!errors.feesPerExtraGuest}
					helperText={errors.feesPerExtraGuest ? errors.feesPerExtraGuest : t('settings.list.feesPerExtraGuest.description')}
					required
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mt={4} mb={2}>
				<Typography variant="h5" component="h2">
					{t('settings.title.externalLinks')}
				</Typography>
			</Box>
			<Box mb={2}>
				<TextInput
					name="openWeatherMapUrl"
					className={classes.longField}
					value={settings.openWeatherMapUrl}
					label={t('settings.list.openWeatherMapUrl.label')}
					error={!!errors.openWeatherMapUrl}
					helperText={
						errors.openWeatherMapUrl ? errors.openWeatherMapUrl : t('settings.list.openWeatherMapUrl.description')
					}
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="googleMapUrl"
					className={classes.longField}
					value={settings.googleMapUrl}
					label={t('settings.list.googleMapUrl.label')}
					error={!!errors.googleMapUrl}
					helperText={errors.googleMapUrl ? errors.googleMapUrl : t('settings.list.googleMapUrl.description')}
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="facebookUrl"
					className={classes.longField}
					value={settings.facebookUrl}
					label={t('settings.list.facebookUrl.label')}
					error={!!errors.facebookUrl}
					helperText={errors.facebookUrl ? errors.facebookUrl : t('settings.list.facebookUrl.description')}
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="instagramUrl"
					className={classes.longField}
					value={settings.instagramUrl}
					label={t('settings.list.instagramUrl.label')}
					error={!!errors.instagramUrl}
					helperText={errors.instagramUrl ? errors.instagramUrl : t('settings.list.instagramUrl.description')}
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="tripAdvisorUrl"
					className={classes.longField}
					value={settings.tripAdvisorUrl}
					label={t('settings.list.tripAdvisorUrl.label')}
					error={!!errors.tripAdvisorUrl}
					helperText={errors.tripAdvisorUrl ? errors.tripAdvisorUrl : t('settings.list.tripAdvisorUrl.description')}
					required
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mt={4} mb={2}>
				<Typography variant="h5" component="h2">
					{t('settings.title.contactInfo')}
				</Typography>
			</Box>
			<Box mb={2}>
				<TextInput
					name="contactMail"
					className={classes.longField}
					value={settings.contactMail}
					label={t('settings.list.contactMail.label')}
					error={!!errors.contactMail}
					helperText={errors.contactMail ? errors.contactMail : t('settings.list.contactMail.description')}
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="careerMail"
					className={classes.longField}
					value={settings.careerMail}
					label={t('settings.list.careerMail.label')}
					error={!!errors.careerMail}
					helperText={errors.careerMail ? errors.careerMail : t('settings.list.careerMail.description')}
					onUpdate={handleFieldUpdate}
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					name="contactPhone"
					className={classes.longField}
					value={settings.contactPhone}
					label={t('settings.list.contactPhone.label')}
					error={!!errors.contactPhone}
					helperText={errors.contactPhone ? errors.contactPhone : t('settings.list.contactPhone.description')}
					onUpdate={handleFieldUpdate}
				/>
			</Box>
		</Form>
	)
}

export default connect(null, { showNotification })(SettingsPageForm)
