import React from 'react'
import { Admin, Resource } from 'react-admin'
import { createBrowserHistory } from 'history'
import LinearProgress from '@material-ui/core/LinearProgress'

import 'styles/fonts.css'
import 'styles/index.css'
import theme from 'styles/theme'
import { RoomBookingList, RoomBookingCreate, RoomBookingEdit } from 'pages/roomBooking'
import { RoomOfferList, RoomOfferCreate, RoomOfferEdit } from 'pages/roomOffer'
import { RoomList, RoomEdit } from 'pages/room'
import { FacilityList, FacilityCreate, FacilityEdit } from 'pages/facility'
import { ActivityList, ActivityCreate, ActivityEdit } from 'pages/activity'
import { UserList, UserCreate, UserEdit } from 'pages/user'
import LoginPage from 'pages/login'
import Layout from 'components/layout'
import authProvider from 'providers/authProvider'
import i18nProvider from 'providers/i18nProvider'
import useDataProvider from 'hooks/useDataProvider'
import { SettingsProvider } from 'contexts/settings'
import DataProviderContext from 'contexts/dataProvider'

import customRoutes from './customRoutes'

const history = createBrowserHistory()

const App = () => {
	const dataProvider = useDataProvider()

	if (!dataProvider) {
		return <LinearProgress />
	}

	return (
		<DataProviderContext.Provider value={dataProvider}>
			<SettingsProvider>
				<Admin
					customRoutes={customRoutes}
					theme={theme}
					history={history}
					authProvider={authProvider}
					dataProvider={dataProvider}
					i18nProvider={i18nProvider}
					loginPage={LoginPage}
					layout={Layout}
				>
					{permission => [
						<Resource name="RoomBooking" key="RoomBooking" list={RoomBookingList} create={RoomBookingCreate} edit={RoomBookingEdit} />,
						<Resource name="Room" key="Room" list={RoomList} edit={RoomEdit} />,
						<Resource name="RoomOffer" key="RoomOffer" list={RoomOfferList} create={RoomOfferCreate} edit={RoomOfferEdit} />,
						<Resource name="Facility" key="Facility" list={FacilityList} create={FacilityCreate} edit={FacilityEdit} />,
						<Resource name="Activity" key="Activity" list={ActivityList} create={ActivityCreate} edit={ActivityEdit} />,
						(permission === 'ADMIN' ? <Resource name="User" key="User" list={UserList} create={UserCreate} edit={UserEdit} /> : null),
					]}
				</Admin>
			</SettingsProvider>
		</DataProviderContext.Provider>
	)
}

export default App
