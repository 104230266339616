import { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslate, showNotification } from 'react-admin'
import { Box, TextField, Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { gql } from '@apollo/client'

import client from 'api/client'

const UPDATE_PASSWORD = gql`
	mutation updateProfile($password: String!) {
		updateProfile(password: $password)
	}
`

const PasswordChanger = props => {
	const t = useTranslate()
	const [value, setValue] = useState('')

	const onChange = e => {
		setValue(e.target.value)
	}

	const onSubmit = () => {
		client.mutate({ mutation: UPDATE_PASSWORD, variables: { password: value } }).then(res => {
			if (res.data.updateProfile) {
				props.showNotification(t('profile.password.success'))
				setValue('')
			} else {
				props.showNotification(t('profile.password.error'))
			}
		}).catch(() => {
			props.showNotification(t('profile.password.error'))
		})
	}

	return (
		<Box display="flex" alignItems="center">
			<TextField
				label={t('profile.password.fieldLabel')}
				value={value}
				onChange={onChange}
				type="password"
				autoComplete="off"
				variant="filled"
			/>
			<Box ml={2}>
				<Button color="primary" disabled={value.length < 8} startIcon={<SaveIcon />} onClick={onSubmit}>
					{t('save')}
				</Button>
			</Box>
		</Box>
	)
}

export default connect(null, { showNotification })(PasswordChanger)
