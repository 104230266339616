import React from 'react'
import { useTranslate } from 'ra-core'

import { styled } from '@material-ui/core/styles'

const Line = styled('p')({
	whiteSpace: 'nowrap'
})

const PeopleField = ({ record }) => {
	const t = useTranslate()

	if (!record) return null

	return (
		<div>
			<Line>{t('adultCount', { smart_count: record.adultCount })}</Line>
			{record.childCount ? <Line>{t('childCount', { smart_count: record.childCount })}</Line> : null}
		</div>
	)
}

export default PeopleField
