import React from 'react'
import dayjs from 'dayjs'

import EditButton from './editButton'
import AcceptButton from './acceptButton'
import RefuseButton from './refuseButton'

const ListActions = ({ record, onAccept, onRefuse }) => {
	if (!record) return null
	const isPending = record.status === 'pending' && !dayjs(record.startDate).isBefore(dayjs())

	return (
		<>
			<EditButton basePath="RoomBooking" record={record} />
			{isPending && (
				<>
					<AcceptButton record={record} handleSuccess={onAccept} />
					<RefuseButton record={record} handleSuccess={onRefuse} />
				</>
			)}
		</>
	)
}

export default ListActions
