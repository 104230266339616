import { useEffect, useRef } from 'react'

const useEffectAfterMounted = (func, deps) => {
	const didMount = useRef(false)

	useEffect(() => {
		if (!didMount.current) {
			didMount.current = true
			return
		}

		func()
	}, deps)
}

export default useEffectAfterMounted
