import { common, red, green, orange } from '@material-ui/core/colors'

const getStatusColor = status => {
	switch (status) {
		case 'pending':
			return orange[300]
		case 'accepted':
			return green[500]
		case 'refused':
			return red[500]
		default:
			return common.white
	}
}

export default getStatusColor
