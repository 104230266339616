import { v4 as uuidv4 } from 'uuid'

const createItem = () => {
	return {
		id: uuidv4(),
		name: '',
		description: '',
		imageName: '',
		order: 1
	}
}

export default createItem
