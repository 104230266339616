import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import AcceptIcon from '@material-ui/icons/Check'
import { green } from '@material-ui/core/colors'

import acceptRoomBooking from 'actions/acceptRoomBooking'

import ConfirmationMailDialog from './confirmationMailDialog'

const StyledButton = styled(Button)({
	color: green[600]
})

const AcceptButton = ({ record, handleSuccess = () => {} }) => {
	const [dialogShown, setDialogShown] = useState(false)
	const [message, setMessage] = useState('We are waiting for you!')

	const handleButtonClick = () => {
		setDialogShown(true)
	}

	const handleSubmit = async () => {
		setDialogShown(false)
		acceptRoomBooking(record.id, message).then(() => {
			handleSuccess()
		})
	}

	const handleMessageChange = e => {
		setMessage(e.target.value)
	}

	const handleDialogClose = () => {
		setDialogShown(false)
	}

	return <>
		<StyledButton onClick={handleButtonClick} size="small" startIcon={<AcceptIcon />}>Accept</StyledButton>
		<ConfirmationMailDialog
			title="Confirm booking"
			open={dialogShown}
			message={message}
			handleMessageChange={handleMessageChange}
			handleSubmit={handleSubmit}
			handleClose={handleDialogClose}
		/>
	</>
}

export default AcceptButton
