import React from 'react'
import { useTranslate } from 'react-admin'
import { Box } from '@material-ui/core'

import TextInput from 'components/textInput'
import ImageInput from 'components/imageInput'

const Fields = props => {
	const t = useTranslate()

	return (
		<>
			<Box mb={2}>
				<ImageInput
					label="Illustrative banner"
					name="imageName"
					value={props.content.imageName}
					onUpdate={props.handleUpdateField}
					helperText="Select an image that will be displayed as a banner at the top of the content (optional)."
					fullWidth
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					label="Title"
					name="title"
					value={props.content.title}
					onUpdate={props.handleUpdateField}
					helperText="Specify a large title which will be displayed at the top of the content (optional)."
					fullWidth
					multiline
				/>
			</Box>
			{!props.content.lockedOrder ? (
				<Box>
					<TextInput
						label="Order"
						name="order"
						type="number"
						value={props.content.order}
						onUpdate={props.handleUpdateField}
						helperText={t('orderHelperText')}
						fullWidth
					/>
				</Box>
			) : null}
		</>
	)
}

export default Fields
