import React from 'react'
import { connect } from 'react-redux'
import { showNotification } from 'react-admin'
import { LinearProgress, Box, Typography } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'

import TextInput from 'components/textInput'
import ImageInput from 'components/imageInput'
import Form from 'components/form'
import useForm from 'hooks/useForm'
import useFormStyles from 'hooks/useFormStyles'
import flattenArray from 'utils/flattenArray'

const title = 'Facility homepage'

const resources = [
	{ resource: 'Text', filter: { slugs: ['facilityMainTitle', 'facilityMainDescription'] } },
	{ resource: 'Image', filter: { slugs: ['homeFacility'] } },
]

const FacilityPageForm = props => {
	const classes = useFormStyles()
	const { data, hasChanges, update, submit } = useForm(resources)

	const handleSubmit = async () => {
		await submit()
		props.showNotification('Page saved!')
	}

	if (isEmpty(data)) {
		return (
			<Form title={title} disableSaveButton={true}>
				<LinearProgress />
			</Form>
		)
	}

	const texts = flattenArray(data.Text, 'slug')
	const images = flattenArray(data.Image, 'slug')

	return (
		<Form title={title} disableSaveButton={!hasChanges} onSubmit={handleSubmit}>
			<Box mb={2}>
				<Typography variant="h5" component="h2">Introduction</Typography>
			</Box>
			<Box mb={2}>
				<ImageInput
					label="Poster image"
					name="imageName"
					value={images.homeFacility.imageName}
					onUpdate={(name, value) => update('Image', images.homeFacility.id, { [name]: value })}
					required
				/>
			</Box>
			<Box mb={2}>
				<TextInput
					label="Title"
					name="value"
					value={texts.facilityMainTitle.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.facilityMainTitle.id, { [name]: value })}
					multiline
					max="20"
				/>
			</Box>
			<Box>
				<TextInput
					label="Description"
					name="value"
					value={texts.facilityMainDescription.value}
					className={classes.longField}
					onUpdate={(name, value) => update('Text', texts.facilityMainDescription.id, { [name]: value })}
					multiline
				/>
			</Box>
		</Form>
	)
}

export default connect(null, { showNotification })(FacilityPageForm)
