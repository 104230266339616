import React from 'react'
import PriceField from 'components/priceField'

const DiscountField = ({ record }) => {
	if (!record) {
		return null
	}
	return record.discountType === 'flat' ? (
		<PriceField record={record} source='discount' />
	) : (
		<span>{record.discount} %</span>
	)
}

export default DiscountField