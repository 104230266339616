import { useState, useEffect } from 'react'
import { CREATE, UPDATE } from 'react-admin'
import buildGraphQLProvider from 'ra-data-graphql-simple'

import client from 'api/client'
import convertFileToBase64 from 'utils/convertFileToBase64'

const overloadProvider = requestHandler => async (type, resource, params) => {
	const data = params.data
	if (type === CREATE || type === UPDATE) {
		// Check if there is file fields
		for (const [field, value] of Object.entries(data)) {
			if (/^imageName$/.test(field) && value.rawFile) {
				// Convert the file to a base64 string and add a new data parameter
				data['imageBase64'] = await convertFileToBase64(value.rawFile)
			}
		}

		if (resource === 'RoomBooking' && data.rooms) {
			// Keep only the identifier, the quantity and the unit price
			data.rooms = data.rooms.map(({ info, quantity, unitPrice }) => ({ roomId: info.id, quantity, unitPrice }))
		}
	}

	return requestHandler(type, resource, {
		...params,
		data
	})
}

const DataProvider = () => {
	const [dataProvider, setDataProvider] = useState(null)

	useEffect(() => {
		buildGraphQLProvider({ client }).then(dataProvider => {
			setDataProvider(() => overloadProvider(dataProvider))
		})
	}, [])

	return dataProvider
}

export default DataProvider
