import React from 'react'

const CustomerField = ({ record }) => {
	if (!record) return null
	return (
		<span>
			{record.firstName} {record.lastName}<br />
			{record.email}<br />
			{record.phone}
		</span>
	)
}

export default CustomerField
