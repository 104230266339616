const groupArray = (items, key) => {
	const newArray = []

	for (const item of items) {
		const group = newArray.find(group => group[key] === item[key])
		if (group) {
			group.items.push(item)
		} else {
			newArray.push({
				[key]: item[key],
				items: [item]
			})
		}
	}

	return newArray
}

export default groupArray
