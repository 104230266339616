import React from 'react'
import { TextInput, NumberInput, ImageInput, required, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { Typography, Box, Grid } from '@material-ui/core'
import { useMediaQuery } from '@material-ui/core'

import PreviewImage from 'components/previewImage'
import formatImageInput from 'utils/formatImageInput'

import InclusiveForm from './inclusiveForm'
import OptionForm from './optionForm'

const Form = props => {
	const form = useForm()
	const t = useTranslate()
	const isLarge = useMediaQuery(theme => theme.breakpoints.up('md'))

	return (
		<>
			<Grid container spacing={4}>
				<Grid item xs={12} md={6}>
					<Box mb={8} mt={2}>
						<Box mb={2}>
							<Typography variant="h5" component="p">
								General information
							</Typography>
							<Typography variant="body1" component="p">
								Fill all information related to this type of room:
							</Typography>
						</Box>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextInput
									source="name"
									fullWidth
									helperText="Name which is displayed in the website."
									validate={required()}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<NumberInput
									source="order"
									min={0}
									max={1000}
									fullWidth
									helperText={t('orderHelperText')}
								/>
							</Grid>
							<Grid item xs={12} xl={6}>
								<TextInput source="shortDescription" multiline fullWidth helperText="Few words about the room." />
							</Grid>
							<Grid item xs={12} xl={6}>
								<TextInput source="description" multiline fullWidth helperText="Full description of the room." />
							</Grid>
							<Grid item xs={12} xl={6}>
								<TextInput
									source="tags"
									fullWidth
									helperText="Main adjectives which describe your room, separated by commas."
								/>
							</Grid>
							{!isLarge ? (
								<Grid item xs={12}>
									<ImageInput source="imageName" format={formatImageInput()} validate={required()} accept="image/*">
										<PreviewImage source="imageName" />
									</ImageInput>
								</Grid>
							) : null}
							<Grid item xs={12} sm={6} xl={4}>
								<NumberInput
									source="surface"
									min={0}
									fullWidth
									helperText="Room surface in sq.m."
									validate={required()}
								/>
							</Grid>
							<Grid item xs={12} sm={6} xl={4}>
								<NumberInput
									source="price"
									min={0}
									fullWidth
									helperText="Price for one night, using the currency defined in settings."
									validate={required()}
								/>
							</Grid>
							<Grid item xs={12} sm={6} xl={4}>
								<NumberInput
									source="baseOccupancy"
									min={0}
									fullWidth
									helperText="Base room occupancy for a normal rate."
									validate={required()}
								/>
							</Grid>
							<Grid item xs={12} sm={6} xl={4}>
								<NumberInput
									source="maxOccupancy"
									min={0}
									fullWidth
									helperText="Maximum room occupancy."
									validate={required()}
								/>
							</Grid>
							<Grid item xs={12} sm={6} xl={4}>
								<NumberInput
									source="totalQuantity"
									min={0}
									fullWidth
									helperText="Total quantity for this kind of room."
									validate={required()}
								/>
							</Grid>
						</Grid>
					</Box>
					<Box mb={8}>
						<Box mb={2}>
							<Typography variant="h5" component="p">
								Options
							</Typography>
							<Typography variant="body1" component="p">
								Specify all possible options this type of room can propose for each of these categories:
							</Typography>
						</Box>
						<OptionForm record={props.record} updateRecord={value => form.change('optionsIds', value)} />
					</Box>
					<Box mb={2}>
						<Box mb={2}>
							<Typography variant="h5" component="p">
								Inclusives
							</Typography>
							<Typography variant="body1" component="p">
								Check every services this type of room can offer:
							</Typography>
						</Box>
						<InclusiveForm record={props.record} updateRecord={value => form.change('inclusivesIds', value)} />
					</Box>
				</Grid>
				{isLarge ? (
					<Grid item xs={12} sm={6} md={6}>
						<ImageInput source="imageName" format={formatImageInput()} validate={required()} accept="image/*">
							<PreviewImage source="imageName" />
						</ImageInput>
					</Grid>
				) : null}
			</Grid>
		</>
	)
}

export default Form
