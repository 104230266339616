const getCurrencySymbol = (currency) => {
	// Use NumberFormat to find the best internationalized currency symbol
	const CurrencyFormat = new Intl.NumberFormat('en', {
		style: 'currency',
		currency: currency
	})
	return CurrencyFormat.formatToParts(0).find(part => part.type === 'currency').value
}

export default getCurrencySymbol
