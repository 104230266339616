import React, { useContext } from 'react'

import { SettingsContext } from 'contexts/settings'
import formatPrice from 'utils/formatPrice'

const PriceField = ({ record = {}, source }) => {
	const { currency } = useContext(SettingsContext)

	let res = record[source]
	if (typeof res === 'number') {
		res = formatPrice(res, currency)
	}

	return <span>{res}</span>
}

export default PriceField
