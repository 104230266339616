const getGridValues = (items, width) => {
	let cols
	if (width === 'xl' || width === 'lg') {
		cols = 3
	} else if (width === 'md') {
		cols = 2
	} else {
		cols = 1
	}

	const rowHeight = 500
	const totalHeight = rowHeight * Math.ceil(items / cols)

	return {
		cols,
		rowHeight,
		totalHeight
	}
}

export default getGridValues
