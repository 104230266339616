const convertFileToBase64 = file => (
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = () => resolve(reader.result)
		reader.onerror = reject

		reader.readAsDataURL(file)
	})
)

export default convertFileToBase64
