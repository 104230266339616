const getImageUrl = (name, width = null, height = null) => {
	if (/^blob:/i.test(name)) {
		// If there is a blob prefix, we shall return it directly as it is a valid url
		return name
	}

	let src = process.env.REACT_APP_API_URI + '/image/' + name
	if (width) {
		src += '/' + width
		if (height) {
			src += '/' + height
		}
	}

	return src
}

export default getImageUrl
