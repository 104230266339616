import React, { useState, useEffect, useContext } from 'react'
import { GET_LIST } from 'react-admin'
import { CircularProgress, FormControlLabel, Checkbox, Grid } from '@material-ui/core'

import DataProviderContext from 'contexts/dataProvider'

const InclusiveForm = ({ record, updateRecord }) => {
	const dataProvider = useContext(DataProviderContext)
	const [inclusives, setInclusives] = useState([])
	const [inclusivesIds, setInclusivesIds] = useState(record.inclusivesIds || [])

	const init = async () => {
		const res = await dataProvider(GET_LIST, 'Inclusive', { filter: { categories: ['room'] } })
		setInclusives(res.data)
	}

	useEffect(() => init(), []) // eslint-disable-line

	const handleChange = e => {
		const { value, checked } = e.target
		const index = inclusivesIds.indexOf(value)
		const shouldAdd = checked && index === -1
		const shouldRemove = !checked && index !== -1

		if (shouldAdd || shouldRemove) {
			const newInclusivesIds = [...inclusivesIds]
			if (shouldAdd) {
				newInclusivesIds.push(value)
			} else if (shouldRemove) {
				newInclusivesIds.splice(index, 1)
			}
			setInclusivesIds(newInclusivesIds)

			// Update directly the record
			updateRecord(newInclusivesIds)
		}
	}

	if (!inclusives.length) {
		return <CircularProgress />
	}

	return (
		<Grid container>
			{inclusives.map(inclusive => (
				<Grid item xs={12} lg={6} key={inclusive.id}>
					<FormControlLabel
						label={inclusive.name}
						control={
							<Checkbox checked={inclusivesIds.includes(inclusive.id)} onChange={handleChange} value={inclusive.id} />
						}
					/>
				</Grid>
			))}
		</Grid>
	)
}

export default InclusiveForm
