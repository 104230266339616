import React from 'react'
import { TextInput, PasswordInput, SelectInput, required, minLength } from 'react-admin'
import { Box } from '@material-ui/core'

const roles = [
	{ id: 'MANAGER', name: 'Manager' },
	{ id: 'ADMIN', name: 'Administrator' },
]

const Form = props => {
	return (
		<>
			<Box>
				<TextInput source="firstName" validate={required()} />
			</Box>
			<Box>
				<TextInput source="lastName" validate={required()} />
			</Box>
			<Box>
				<TextInput source="email" validate={required()} inputProps={{ autoComplete: 'off' }} />
			</Box>
			<Box>
				<SelectInput source="role" choices={roles} validate={required()} />
			</Box>
			<Box>
				{props.record.id ? (
					<PasswordInput source="password" label="New password" validate={minLength(8)} />
				) : (
					<PasswordInput source="password" validate={[required(), minLength(8)]} inputProps={{ autoComplete: 'off' }} />
				)}
			</Box>
		</>
	)
}

export default Form
