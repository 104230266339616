import React from 'react'
import { ArrayField, ReferenceField, TextField } from 'react-admin'

import SimpleDatagrid from './simpleDatagrid'

const RoomName = props => (
	// Keep only the initial letters
	<span>
		{props.record.name
			.split(' ')
			.map(word => word[0])
			.join('')}
	</span>
)

const RoomField = props => (
	<ArrayField source="rooms" {...props} sortable={false}>
		<SimpleDatagrid>
			<TextField source="quantity" />
			&nbsp;x&nbsp;
			<ReferenceField label="Room" source="info.id" reference="Room">
				<RoomName />
			</ReferenceField>
		</SimpleDatagrid>
	</ArrayField>
)

export default RoomField
