import React, { useState, useEffect } from 'react'
import { Title, useTranslate } from 'react-admin'
import { Card, CardContent, Typography, Box } from '@material-ui/core'
import decode from 'jwt-decode'

import NotificationSwitcher from './components/notificationSwitcher'
import PasswordChanger from './components/passwordChanger'

const Preferences = () => {
	const t = useTranslate()
	const [profile, setProfile] = useState({})

	useEffect(() => {
		const profile = decode(localStorage.getItem('token'))
		if (profile) {
			setProfile(profile)
		}
	}, [])

	return (
		<Card>
			<Title title={t('profile.title', { firstName: profile.firstName })} />
			<CardContent>
				<Box mb={1}>
					<Typography variant="h5" component="h2">
						{t('profile.notifications.label')}
					</Typography>
				</Box>
				<Box mb={4}>
					<NotificationSwitcher />
				</Box>
				<Box mb={1}>
					<Typography variant="h5" component="h2">
						{t('profile.password.label')}
					</Typography>
				</Box>
				<Box>
					<PasswordChanger />
				</Box>
			</CardContent>
		</Card>
	)
}

export default Preferences
