import React from 'react'
import { DateField } from 'react-admin'

const OfferDateField = ({ record, startSource, endSource }) => (
	<>
		<DateField record={record} source={startSource} />
		<br />
		<DateField record={record} source={endSource} />
	</>
)

export default OfferDateField