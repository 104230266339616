import React from 'react'
import { List, Datagrid, NumberField, TextField, EditButton } from 'react-admin'
import { useMediaQuery } from '@material-ui/core'

import QueryFilter from 'components/queryFilter'
import ImageAndNameField from 'components/imageAndNameField'
import PriceField from 'components/priceField'
import useGridStyles from 'hooks/useGridStyles'


const RoomList = props => {
	const isMedium = useMediaQuery(theme => theme.breakpoints.up('sm'))
	const isLarge = useMediaQuery(theme => theme.breakpoints.up('md'))
	const isExtraLarge = useMediaQuery(theme => theme.breakpoints.up('lg'))
	const classes = useGridStyles()

	return (
		<List filters={<QueryFilter />} sort={{ field: 'order', order: 'desc' }} {...props}>
			<Datagrid rowClick="edit">
				{isMedium && <NumberField source="order" />}
				<ImageAndNameField source="name" cellClassName={!isLarge ? classes.fullWidth : null} />
				{isLarge && <TextField source="shortDescription" />}
				{isLarge && <TextField source="totalQuantity" />}
				{isExtraLarge && <TextField source="description" />}
				{isLarge && <TextField source="surface" />}
				{isLarge && <PriceField source="price" />}
				<EditButton />
			</Datagrid>
		</List>
	)
}

export default RoomList
