import React from 'react'
import { TabbedForm, Toolbar, FormTab } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'

import usePageContent from 'hooks/usePageContent'

import PageContentForm from './pageContentForm'
import getPageSlug from './helpers/getPageSlug'

const useStyles = makeStyles(theme => ({
	errorIcon: {
		fill: theme.palette.error.main,
		margin: theme.spacing(0.75),
	},
}))

const withContentForm = props => {
	const pageSlug = getPageSlug(props.resource, props.record)
	const { contents, updateContent, updateContentErrors, submitContents, hasContentErrors } = usePageContent(pageSlug)
	const classes = useStyles()

	const save = (data, redirectTo, option) => {
		if (hasContentErrors) return

		// Save entity
		props.save(data, redirectTo, option)

		// Save associated contents
		submitContents()
	}

	return (
		<TabbedForm {...props} toolbar={<Toolbar alwaysEnableSaveButton />} save={save}>
			<FormTab label="Information">
				{props.children}
			</FormTab>
			<FormTab
				label="Page content"
				path="content"
				icon={hasContentErrors ? <ErrorIcon className={classes.errorIcon} /> : null}
			>
				<PageContentForm contents={contents} onUpdate={updateContent} onErrorsChange={updateContentErrors} />
			</FormTab>
		</TabbedForm>
	)
}

export default withContentForm
