import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { showNotification, useTranslate } from 'react-admin'
import { LinearProgress, Box, Typography, Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { gql } from '@apollo/client'
import isEqual from 'lodash/isEqual'

import client from 'api/client'
import Form from 'components/form'
import groupArray from 'utils/groupArray'

const GET_INCLUSIVES = gql`
	query {
		allInclusives(filter: { notCategories: ["room"] }) {
			id
			category
			name
			shortName
		}
		allResortInclusives {
			id
		}
	}
`

const REPLACE_INCLUSIVES = gql`
	mutation replaceResortInclusives($ids: [String]) {
		replaceResortInclusives(ids: $ids)
	}
`

const InclusivesPageForm = ({ showNotification }) => {
	const t = useTranslate()
	const [state, setState] = useState(null)

	const getInclusives = () => {
		client
			.query({
				query: GET_INCLUSIVES,
				fetchPolicy: 'no-cache',
			})
			.then(res => {
				const allInclusives = groupArray(res.data.allInclusives, 'category')
				const ids = res.data.allResortInclusives.map(({ id }) => id)
				setState({
					allInclusives: allInclusives,
					initialIds: ids,
					ids: ids
				})
			})
	}

	const replaceInclusives = ids => {
		client
			.mutate({
				mutation: REPLACE_INCLUSIVES,
				variables: { ids },
			})
			.then(() => {
				setState(prevState => ({
					...prevState,
					initialIds: ids
				}))
				showNotification('Inclusives saved.')
			})
	}

	const handleChange = e => {
		const inclusiveId = e.target.name
		if (e.target.checked) {
			setState(prevState => ({
				...prevState,
				ids: [...prevState.ids, inclusiveId]
			}))
		} else {
			setState(prevState => ({
				...prevState,
				ids: prevState.ids.filter(id => id !== inclusiveId)
			}))
		}
	}

	const handleSubmit = () => {
		replaceInclusives(state.ids)
	}

	useEffect(() => {
		getInclusives()
	}, [])

	if (!state) {
		return (
			<Form title="Inclusives" disableSaveButton={true}>
				<LinearProgress />
			</Form>
		)
	}

	// Disable the save button if we have no changes yet
	const disableSaveButton = isEqual(state.ids, state.initialIds)

	return (
		<Form title="Inclusives" disableSaveButton={disableSaveButton} onSubmit={handleSubmit}>
			{state.allInclusives.map(({ category, items }) => (
				<Box mb={4} key={category}>
					<Box mb={1}>
						<Typography variant="h5" component="h2">
							{t(`inclusiveCategory.${category}`)}
						</Typography>
					</Box>
					<Grid container>
						{items.map(inclusive => (
							<Grid item xs={12} key={inclusive.id}>
								<FormControlLabel
									control={<Checkbox name={inclusive.id} checked={state.ids.includes(inclusive.id)} onChange={handleChange} />}
									label={inclusive.name}
								/>
							</Grid>
						))}
					</Grid>
				</Box>
			))}
		</Form>
	)
}

export default connect(null, { showNotification })(InclusivesPageForm)
