import React from 'react'
import { List, Datagrid, NumberField, TextField, EditButton, DeleteButton } from 'react-admin'
import { useMediaQuery } from '@material-ui/core'

import QueryFilter from 'components/queryFilter'
import useGridStyles from 'hooks/useGridStyles'
import ImageAndNameField from 'components/imageAndNameField'

const FacilityList = props => {
	const isMedium = useMediaQuery(theme => theme.breakpoints.up('sm'))
	const isLarge = useMediaQuery(theme => theme.breakpoints.up('md'))
	const isExtraLarge = useMediaQuery(theme => theme.breakpoints.up('lg'))
	const classes = useGridStyles()

	return (
		<List filters={<QueryFilter />} sort={{ field: 'order', order: 'desc' }} {...props}>
			<Datagrid rowClick="edit">
				{isMedium && <NumberField source="order" />}
				<ImageAndNameField source="name" cellClassName={!isLarge ? classes.fullWidth : null} />
				{isLarge && <TextField source="shortDescription" />}
				{isExtraLarge && <TextField source="description" />}
				<EditButton />
				<DeleteButton />
			</Datagrid>
		</List>
	)
}

export default FacilityList
