import React from 'react'
import { List, Datagrid, TextField, NumberField, EditButton, DeleteButton } from 'react-admin'
import { useMediaQuery } from '@material-ui/core'

import QueryFilter from 'components/queryFilter'
import PriceField from 'components/priceField'
import ImageAndNameField from 'components/imageAndNameField'
import useGridStyles from 'hooks/useGridStyles'

const ActivityList = props => {
	const isMedium = useMediaQuery(theme => theme.breakpoints.up('sm'))
	const isLarge = useMediaQuery(theme => theme.breakpoints.up('md'))
	const classes = useGridStyles()

	return (
		<List filters={<QueryFilter />} sort={{ field: 'order', order: 'desc' }} {...props}>
			<Datagrid rowClick="edit">
				{isMedium && <NumberField source="order" />}
				<ImageAndNameField source="name" cellClassName={!isLarge ? classes.fullWidth : null} />
				{isLarge && <TextField source="shortDescription" />}
				{isLarge && <PriceField source="pricePerPax" />}
				{isLarge && <NumberField source="minPax" />}
				<EditButton />
				<DeleteButton />
			</Datagrid>
		</List>
	)
}

export default  ActivityList
