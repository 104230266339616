import React, { isValidElement, cloneElement } from 'react'
import { useListContext } from 'react-admin'
import { styled } from '@material-ui/core'

const Grid = styled('div')({
	display: 'inline-grid',
	gridTemplateColumns: props => `repeat(${props.cols}, auto)`,
	gridGap: '.5em'
})

const SimpleDatagrid = props => {
	const { basePath, resource, ids, data } = useListContext(props)

	return (
		<Grid cols={props.children.length}>
			{ids.map(id => (
				props.children.map((child, key) => (
					isValidElement(child) && typeof child.type !== 'string' ? cloneElement(child, {
						key,
						basePath: child.props.basePath || basePath,
						record: data[id],
						resource
					}) : child
				))
			))}
		</Grid>
	)
}

export default SimpleDatagrid
