import React, { useState } from 'react'
import { Box, withWidth } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd'

import { getGridValues, getRequiredItemFields } from '../lib'
import Item from './item'

const useStyles = makeStyles(theme => ({
	itemSection: {
		marginLeft: -theme.spacing(2),
		marginRight: -theme.spacing(2),
		padding: theme.spacing(1),
	},
	gridItem: {
		padding: theme.spacing(1),
	},
	item: {
		width: '100%',
		height: '100%',
		overflowY: 'auto',
	},
}))

const ContentForm = props => {
	const classes = useStyles()
	const [disableDragAndDrop, setDisableDragAndDrop] = useState(false)
	const { cols, rowHeight, totalHeight } = getGridValues(props.content.items.length, props.width)
	const requiredItemFields = getRequiredItemFields(props.content.type)

	const handleItemInputFocus = () => {
		setDisableDragAndDrop(true)
	}

	const handleItemInputBlur = () => {
		setDisableDragAndDrop(false)
	}


	const handleDragAndDrop = (_, sourceIndex, targetIndex) => {
		// Swap two items
		let items = swap(props.content.items, sourceIndex, targetIndex)

		props.handleMoveItems(items)
	}

	return (
		<>
			{props.content.items.length > 0 ? (
				<Box className={classes.itemSection}>
					<GridContextProvider onChange={handleDragAndDrop}>
						<GridDropZone
							disableDrag={disableDragAndDrop}
							boxesPerRow={cols}
							rowHeight={rowHeight}
							style={{ height: totalHeight }}
						>
							{props.content.items.map((item, index) => (
								<GridItem key={item.id} className={classes.gridItem}>
									<Item
										item={item}
										requiredFields={requiredItemFields}
										errors={props.errors[item.id]}
										index={index}
										className={classes.item}
										onItemChange={props.handleUpdateItem}
										onItemDelete={props.handleDeleteItem}
										onInputFocus={handleItemInputFocus}
										onInputBlur={handleItemInputBlur}
										canDelete={props.content.items.length > props.content.min}
									/>
								</GridItem>
							))}
						</GridDropZone>
					</GridContextProvider>
				</Box>
			) : <p>No items.</p>}
		</>
	)
}

export default withWidth()(ContentForm)
