import React, { useState, useEffect, useContext } from 'react'
import { GET_LIST } from 'react-admin'
import { CircularProgress, InputLabel, FormControl, Select, MenuItem, ListItemText, Grid, Checkbox } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

import DataProviderContext from 'contexts/dataProvider'

const StyledInputLabel = styled(InputLabel)({
	'&:first-letter': {
		textTransform: 'uppercase'
	}
})

const StyledFormControl = styled(FormControl)({
	width: '100%'
})

const InclusiveForm = ({ record, updateRecord }) => {
	const dataProvider = useContext(DataProviderContext)
	const [allOptions, setAllOptions] = useState(null)
	const [categorizedOptions, setCategorizedOptions] = useState(null)
	const [optionsIds, setOptionsIds] = useState(record.optionsIds || [])

	const init = async () => {
		const res = await dataProvider(GET_LIST, 'Option', {})
		setAllOptions(res.data)
	}

	useEffect(() => init(), []) // eslint-disable-line

	useEffect(() => {
		if (!allOptions) {
			return
		}

		// Regroup options by categories
		setCategorizedOptions(allOptions.reduce((result, item) => ({
			...result,
			[item.category]: [
				...(result[item.category] || []),
				item,
			],
		}), {}))
	}, [allOptions])

	const handleChange = e => {
		const { name, value: newValues } = e.target
		const prevValues = categorizedOptions[name].map(o => o.id).filter(id => optionsIds.includes(id))
		const elementsToRemove = prevValues.filter(x => !newValues.includes(x))
		const elementsToAdd = newValues.filter(x => !prevValues.includes(x))

		if (elementsToAdd.length || elementsToRemove.length) {
			let newOptionsIds
			if (elementsToAdd.length) {
				newOptionsIds = [...optionsIds, ...elementsToAdd]
			} else if (elementsToRemove.length) {
				newOptionsIds = optionsIds.filter(x => !elementsToRemove.includes(x))
			}
			setOptionsIds(newOptionsIds)

			// Update directly the record
			updateRecord(newOptionsIds)
		}
	}

	return categorizedOptions ? (
		<Grid container spacing={2}>
			{Object.entries(categorizedOptions).map(([category, options], key) => (
				<Grid item xs={12} sm={6} key={key}>
					<StyledFormControl variant="filled" margin="dense">
						<StyledInputLabel>{category}</StyledInputLabel>
						<Select
							multiple
							name={category}
							value={optionsIds.filter(id => options.map(o => o.id).includes(id))}
							onChange={handleChange}
							renderValue={selected => selected.map(id => allOptions.find(o => o.id === id)?.name).join(' OR ')}
						>
							{options.map(option => (
								<MenuItem key={option.id} value={option.id}>
									<Checkbox checked={optionsIds.indexOf(option.id) > -1} />
									<ListItemText primary={option.name} />
								</MenuItem>
							))}
						</Select>
					</StyledFormControl>
				</Grid>
			))}
		</Grid>
	) : (
		<CircularProgress />
	)
}

export default InclusiveForm
