import * as React from 'react'
import { sanitizeListRestProps, useListContext, useTranslate } from 'react-admin'
import { List, Card, CardContent, CardActions, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import countries from 'data/countries.json'

import ListActions from './listActions'

const useStyles = makeStyles(theme => ({
	list: {
		backgroundColor: theme.palette.background.lighter,
		padding: theme.spacing(0.5),
	},
	card: {
		margin: theme.spacing(2),
		lineHeight: 1.5,
	},
	description: {
		color: theme.palette.text.tertiary,
	},
	link: {
		color: theme.palette.primary.main,
	},
}))

const CardList = props => {
	const { recordStyle, onAccept, onRefuse, ...rest } = props
	const { data, ids, loaded, total } = useListContext(props)
	const classes = useStyles(props)
	const t = useTranslate()

	// Retrieve rest props with extra sanitization
	let restProps = sanitizeListRestProps(rest)
	delete restProps.hasBulkActions

	if (loaded === false) {
		return null
	}

	return (
		total > 0 && (
			<List className={classes.list} {...restProps}>
				{ids.map((id, index) => (
					<Card className={classes.card} style={recordStyle ? recordStyle(data[id], index) : undefined} key={index}>
						<CardContent>
							<Box mb={2}>
								<Typography variant="h6" components="h2">
									Booking {data[id].number} - {new Date(data[id].createdAt).toLocaleDateString()}
								</Typography>
							</Box>
							<Box mb={2}>
								<p className={classes.description}>
									From <strong>{new Date(data[id].startDate).toLocaleDateString()}</strong> to{' '}
									<strong>{new Date(data[id].endDate).toLocaleDateString()}</strong>
								</p>
								<p className={classes.description}>
									With <strong>{t('adultCount', { smart_count: data[id].adultCount })} </strong>
									{data[id].childCount ? (
										<span>
											and <strong>{t('childCount', { smart_count: data[id].childCount })}</strong>
										</span>
									) : null}
								</p>
							</Box>
							<Box>
								<p className={classes.description}>
									{data[id].firstName} {data[id].lastName}
								</p>
								<p className={classes.description}>
									<a href={`mailto:${data[id].email}`} className={classes.link}>{data[id].email}</a>
								</p>
								<p className={classes.description}>
									<a href={`tel:${data[id].phone}`} className={classes.link}>{data[id].phone}</a>
								</p>
								<p className={classes.description}>
									{countries.find(({ code }) => code === data[id].country)?.name}
								</p>
							</Box>
						</CardContent>
						<CardActions>
							<ListActions record={data[id]} onAccept={onAccept} onRefuse={onRefuse} />
						</CardActions>
					</Card>
				))}
			</List>
		)
	)
}

export default CardList
