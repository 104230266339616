import React, { useState } from 'react'
import dayjs from 'dayjs'
import { List, Datagrid, DateField, useRefresh } from 'react-admin'
import { Snackbar, useMediaQuery } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import QueryFilter from 'components/queryFilter'
import PriceField from 'components/priceField'

import CardList from './components/cardList'
import ListActions from './components/listActions'
import NumberField from './components/numberField'
import PeopleField from './components/peopleField'
import CustomerField from './components/customerField'
import RoomField from './components/roomField'
import getStatusColor from './utils/getStatusColor'

const getRowStyle = record => {
	if (!record) return {}

	const style = {
		borderLeft: `3px solid ${getStatusColor(record.status)}`,
	}

	if (dayjs(record.endDate).isBefore(dayjs())) {
		style.opacity = 0.38
	}

	return style
}

const RoomBookingList = props => {
	const refresh = useRefresh()
	const [alert, setAlert] = useState({ isShown: false, message: '' })
	const isLarge = useMediaQuery(theme => theme.breakpoints.up('md'))

	const handleAcceptBooking = () => {
		setAlert({ isShown: true, message: 'The booking has been accepted.' })
		refresh()
	}

	const handleRefuseBooking = () => {
		setAlert({ isShown: true, message: 'The booking has been refused.' })
		refresh()
	}

	const handleAlertClose = () => {
		setAlert({ ...alert, isShown: false })
		refresh()
	}

	return (
		<>
			<List {...props} sort={{ field: 'createdAt', order: 'desc' }} filters={<QueryFilter />}>
				{isLarge ? (
					<Datagrid rowStyle={getRowStyle}>
						<NumberField />
						<DateField source="createdAt" />
						<DateField source="startDate" />
						<DateField source="endDate" />
						<PeopleField label="Guest" />
						<RoomField label="Rooms" />
						<CustomerField label="Guest" />
						<PriceField source="amount" />
						<ListActions refresh={refresh} setAlert={setAlert} onAccept={handleAcceptBooking} onRefuse={handleRefuseBooking} />
					</Datagrid>
				) : (
					<CardList recordStyle={getRowStyle} onAccept={handleAcceptBooking} onRefuse={handleRefuseBooking} />
				)}
			</List>
			<Snackbar open={alert.isShown} autoHideDuration={6000} onClose={handleAlertClose}>
				<Alert onClose={handleAlertClose} severity="success">
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	)
}

export default RoomBookingList
